import React from "react";
import { Fragment } from "react";
import Navigation from "../Layout/Navigation";
import Footer from "../Layout/Footer";

const ThemeWrapper = (props) => {
  return (
    <Fragment>
      <Navigation />
      <div className="container-site-holder">{props.children}</div>
      <Footer />
    </Fragment>
  );
};

export default ThemeWrapper;
