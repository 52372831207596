import React, { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/stari/HelmetSeo";
import Contact from "../../components/Site/Contact";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchProductsListItems } from "../../actions/Site/global";
import { useState } from "react";
import i18next from "i18next";
import BlogList from "./BlogList";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../utils";
import BlogItem from "./BlogItem";
import ProductItem from "./ProductItem";

const Products = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { dataPagesListProducts } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchProductsListItems());
  }, [dispatch]);

  console.log(dataPagesListProducts, "dataPagesListProducts");

  return (
    <Fragment>
      <HelmetSeo
        title="Proizvodi | Prečišćivači"
        description="Prečišćivači"
        keywords="Prečišćivači"
      />
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-5 p-0">
            <div
              class="image-banner-top"
              style={{
                backgroundImage: "url(/images/1663232506-foto-baner-png.png)",
              }}
            ></div>
          </div>
          <div class="col-sm-7 content-banner-text-backgorund">
            <div class="row">
              <div class="col-xxl-8 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12">
                <div class="image-banner-top-content">
                  <h1></h1>
                  <p>&nbsp;</p>

                  <p>
                    U nastavku pogledajte listu prečišćivača vazduha, brendova
                    Sharp, Sencor, Beko, Karcher, Whirlpool i Xaomi sa njihovim
                    najvažnijim specifikacijama i pronađite najbolji prečišćivač
                    vazduha za vaš dom.
                  </p>

                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container global-catalog-id">
        <div class="row-catalog mt-4">
          <div class="catalog-filter-mobile-show">
            <div class="catalog-filter-mobile-item karlaMedium">
              Filteri{" "}
              <img src="/images/filter-icon.png" class="filter-icon-mobile" />
            </div>
          </div>
          <div class="col-filter">
            <div class="filter-title green-color karlaMedium">Filteri</div>
            <div class="filter-content-holder">
              <div class="filter-content-group">
                <div class="filter-content-title green-color karlaMedium">
                  Najtraženiji proizvodi
                </div>
                <ul>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Najtraženiji proizvodi"
                        data-filterroute="Prikaži proizvode"
                        data-filterid="40"
                        type="checkbox"
                        value=""
                        id="filterCheck40"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck40">
                        Prikaži proizvode
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="filter-content-group">
                <div class="filter-content-title green-color karlaMedium">
                  Kvadratura
                </div>
                <ul>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Kvadratura"
                        data-filterroute="do 30m2"
                        data-filterid="9"
                        type="checkbox"
                        value=""
                        id="filterCheck9"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck9">
                        do 30m2
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Kvadratura"
                        data-filterroute="do 50m2"
                        data-filterid="10"
                        type="checkbox"
                        value=""
                        id="filterCheck10"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck10">
                        do 50m2
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Kvadratura"
                        data-filterroute="do 80m2"
                        data-filterid="11"
                        type="checkbox"
                        value=""
                        id="filterCheck11"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck11">
                        do 80m2
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="filter-content-group">
                <div class="filter-content-title green-color karlaMedium">
                  Brendovi
                </div>
                <ul>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Brendovi"
                        data-filterroute="Sharp"
                        data-filterid="43"
                        type="checkbox"
                        value=""
                        id="filterCheck43"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck43">
                        Sharp
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Brendovi"
                        data-filterroute="Sencor"
                        data-filterid="44"
                        type="checkbox"
                        value=""
                        id="filterCheck44"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck44">
                        Sencor
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Brendovi"
                        data-filterroute="Beko"
                        data-filterid="45"
                        type="checkbox"
                        value=""
                        id="filterCheck45"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck45">
                        Beko
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Brendovi"
                        data-filterroute="Karcher"
                        data-filterid="42"
                        type="checkbox"
                        value=""
                        id="filterCheck42"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck42">
                        Karcher
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Brendovi"
                        data-filterroute="Whirlpool"
                        data-filterid="41"
                        type="checkbox"
                        value=""
                        id="filterCheck41"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck41">
                        Whirlpool
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Brendovi"
                        data-filterroute="Xiaomi"
                        data-filterid="46"
                        type="checkbox"
                        value=""
                        id="filterCheck46"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck46">
                        Xiaomi
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="filter-content-group">
                <div class="filter-content-title green-color karlaMedium">
                  Jonizacija
                </div>
                <ul>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Jonizacija"
                        data-filterroute="Da"
                        data-filterid="12"
                        type="checkbox"
                        value=""
                        id="filterCheck12"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck12">
                        Da
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Jonizacija"
                        data-filterroute="Ne"
                        data-filterid="13"
                        type="checkbox"
                        value=""
                        id="filterCheck13"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck13">
                        Ne
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="filter-content-group">
                <div class="filter-content-title green-color karlaMedium">
                  Ovlaživanje vazduha
                </div>
                <ul>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Ovlaživanje vazduha"
                        data-filterroute="Da"
                        data-filterid="14"
                        type="checkbox"
                        value=""
                        id="filterCheck14"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck14">
                        Da
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Ovlaživanje vazduha"
                        data-filterroute="Ne"
                        data-filterid="15"
                        type="checkbox"
                        value=""
                        id="filterCheck15"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck15">
                        Ne
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="filter-content-group">
                <div class="filter-content-title green-color karlaMedium">
                  Boja
                </div>
                <ul>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Boja"
                        data-filterroute="Braon"
                        data-filterid="5"
                        type="checkbox"
                        value=""
                        id="filterCheck5"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck5">
                        Braon
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Boja"
                        data-filterroute="Siva"
                        data-filterid="8"
                        type="checkbox"
                        value=""
                        id="filterCheck8"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck8">
                        Siva
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Boja"
                        data-filterroute="Crna"
                        data-filterid="7"
                        type="checkbox"
                        value=""
                        id="filterCheck7"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck7">
                        Crna
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input changeFiltersV2 customControlInput"
                        data-grouproute="Boja"
                        data-filterroute="Bela"
                        data-filterid="6"
                        type="checkbox"
                        value=""
                        id="filterCheck6"
                        data-type=""
                      />
                      <label class="form-check-label" for="filterCheck6">
                        Bela
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="catalog-mobile-filters-take catalog-filter-mobile-show"></div>
          <div class="col-products">
            <div class="row col-products-list">
              {dataPagesListProducts?.map((item, key) => (
                <ProductItem item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Products;
