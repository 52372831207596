import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataPagesListClients: [],
  dataPagesListTestimonials: [],
  dataPagesListDocuments: [],
  dataPagesListCaseStudy: [],
  dataPagesListProducts: [],
  dataPagesListQa: [],
  dataPagesListDocumentsAll: [],
  dataPagesListPartners: [],
  dataPagesListBlogAll: [],
  fetchItemsListSpecification: [],
  fetchItemsListInfo: [],
  loading: false,
  error: undefined,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
    actionStart(state) {
      state.loading = true;
      state.error = undefined;
    },
    fetchItemsListClients(state, action) {
      state.dataPagesListClients = action.payload;
    },
    fetchItemsListTestimonials(state, action) {
      state.dataPagesListTestimonials = action.payload;
    },
    fetchItemsListDocumentsAll(state, action) {
      state.dataPagesListDocumentsAll = action.payload;
    },
    fetchItemsListBlogAll(state, action) {
      state.dataPagesListBlogAll = action.payload;
    },
    fetchItemsListDocuments(state, action) {
      state.dataPagesListDocuments = action.payload;
    },
    fetchItemsListCaseStudy(state, action) {
      state.dataPagesListCaseStudy = action.payload;
    },
    fetchItemsListQa(state, action) {
      state.dataPagesListQa = action.payload;
    },
    fetchItemsListProducts(state, action) {
      state.dataPagesListProducts = action.payload;
    },
    fetchItemsListPartners(state, action) {
      state.dataPagesListPartners = action.payload;
    },
    fetchItemsListSpecification(state, action) {
      state.fetchItemsListSpecification = action.payload;
    },
    fetchInfoListBlogAll(state, action) {
      state.fetchItemsListInfo = action.payload;
    },
  },
});

export const globalActions = globalSlice.actions;

export default globalSlice.reducer;
