import React from "react";
import { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/stari/HelmetSeo";
import ContactForm from "../../components/Site/Contact";
import { Link } from "react-router-dom";

const Brands = () => {
  return (
    <Fragment>
      <HelmetSeo
        title="Brendovi | Prečišćivači"
        description="Prečišćivači"
        keywords="Prečišćivači"
      />

      <div class="container-fluid brands-page">
        <div class="row brands-banner-holder">
          <div class="col-sm-12 p-0">
            <img
              class="w-100 desktop-banner-image-main"
              alt="xiaomi baner"
              src="/images/brendovi-banner.png"
            />
            <img
              class="w-100 mobile-banner-image-main"
              alt="xiaomi baner"
              src="/images/baner-mob.png"
            />
          </div>
        </div>
        <div class="row brands-holder">
          <img
            class="img-fluid brand-img desktop-brand-image-main"
            alt="xiaomi"
            src="/images/sharp-back.jpg"
          />
          <img
            class="img-fluid brand-img mobile-brand-image-main"
            alt="xiaomi"
            src="/images/1663665355-Group-35-png.png"
          />
          <div class="col-sm-6 brand-info">
            <img class="img-fluid brand-logo" src="/images/sharp-logo.png" />
            <p style={{ textAlign: "justify" }}>
              Sharp japanska tradicija duga 110 godina sa inovativnim i
              originalnim pristupom stvaranju električnih uređaja osvojila je
              vrh svetskog tržišta.
            </p>

            <p style={{ textAlign: "justify" }}>
              U svom asortimanu Sharp nudi veliki izbor prečišćivača vazduha,
              različitih kapaciteta i dimenzija, sa jedinstvenom Plasmacluster
              tehnologijom koja obezbeđuje maksimalnu svežinu i čistoću vazduha.
            </p>

            <Link to="/brend-detaljno/1">
              <button type="button" class="brands-custom-button" data-id="1">
                Saznaj više{" "}
                <img class="img-fluid" src="/images/arrow_right.png" />
              </button>
            </Link>
          </div>
        </div>
        <div class="row brands-holder">
          <img
            class="img-fluid brand-img desktop-brand-image-main"
            alt="xiaomi"
            src="/images/sencor.jpg"
          />
          <img
            class="img-fluid brand-img mobile-brand-image-main"
            alt="xiaomi"
            src="/images/sencor-mob.png"
          />
          <div class="col-sm-6 brand-info">
            <img class="img-fluid brand-logo" src="/images/sencor-logo.png" />
            <p style={{ textAlign: "justify" }}>
              Sencor, kompanija koja već 50 godina uspešno proizvodi kućne
              aparate, danas se može pohvaliti sa preko hiljadu artikala u šest
              kategorija proizvoda i poslovanjem na 55 svetskih tržišta.
            </p>

            <p style={{ textAlign: "justify" }}>
              <br />
              Asortiman Sencor prečišćivača donosi efikasno rešenje za
              poboljšanje kvaliteta života, modele sa višestrukom filtracijom,
              kao i prečišćivač i ovlaživač vazduha u jednom, za 99,92 % manje
              štetnih materija u vašem domu.
            </p>

            <Link to="/brend-detaljno/2">
              <button type="button" class="brands-custom-button" data-id="1">
                Saznaj više{" "}
                <img class="img-fluid" src="/images/arrow_right.png" />
              </button>
            </Link>
          </div>
        </div>
        <div class="row brands-holder">
          <img
            class="img-fluid brand-img desktop-brand-image-main"
            alt="xiaomi"
            src="/images/beko-back.jpg"
          />
          <img
            class="img-fluid brand-img mobile-brand-image-main"
            alt="xiaomi"
            src="/images/1663665587-Group-36-png.png"
          />
          <div class="col-sm-6 brand-info">
            <img
              class="img-fluid brand-logo"
              src="/images/1668500469-Beko_logo-png.png"
            />
            <p style={{ textAlign: "justify" }}>
              Beko širok asortiman proizvoda pronašao je svoj put do čak 135
              zemalja širom sveta i postao prepoznatljiv po kvalitetu svojih
              uređaja bele tehnike, malih i velikih kućnih aparata i
              prečišćivača vazduha.
            </p>

            <p style={{ textAlign: "justify" }}>
              Velika efikasnost Beko prečišćivača vazduha donosi i do 99,99 %
              manje bakterija, čestica i virusa, stvarajući na taj način
              zdravije i kvalitetnije okruženje u domu.
            </p>

            <Link to="/brend-detaljno/3">
              <button type="button" class="brands-custom-button" data-id="1">
                Saznaj više{" "}
                <img class="img-fluid" src="/images/arrow_right.png" />
              </button>
            </Link>
          </div>
        </div>
        <div class="row brands-holder">
          <img
            class="img-fluid brand-img desktop-brand-image-main"
            alt="xiaomi"
            src="/images/xiaoMI.jpg"
          />
          <img
            class="img-fluid brand-img mobile-brand-image-main"
            alt="xiaomi"
            src="/images/1663665713-Group-37-png.png"
          />
          <div class="col-sm-6 brand-info">
            <img class="img-fluid brand-logo" src="/images/xiaomi-logo.png" />
            <p style={{ textAlign: "justify" }}>
              Xiaomi kineska kompanija je za samo deset godina od osnivanja
              postala sinonim za savremena rešenja koja olakšavaju
              svakodnevnicu, a svojom pristupačnošću je u kratkom roku osvojila
              potrošače.&nbsp;
              <br />
              <br />
              Kao jedan od pet vodećih proizvođača potrošačke elektronike i
              savremenih rešenja poput prečišćivača vazduha, Xiaomi u svojoj
              ponudi obuhvata prečišćivače vazduha koji obezbeđuju do 99,97 %
              manje dima, polena, dlaka kućnih ljubimaca i drugih štetnih
              čestica u vašem domu.&nbsp;
            </p>

            <Link to="/brend-detaljno/4">
              <button type="button" class="brands-custom-button" data-id="1">
                Saznaj više{" "}
                <img class="img-fluid" src="/images/arrow_right.png" />
              </button>
            </Link>
          </div>
        </div>
        <div class="row brands-holder">
          <img
            class="img-fluid brand-img desktop-brand-image-main"
            alt="xiaomi"
            src="/images/1674564906-baner-2-1-png.png"
          />
          <img
            class="img-fluid brand-img mobile-brand-image-main"
            alt="xiaomi"
            src="/images/1674567295-mobilni-2-1-png.png"
          />
          <div class="col-sm-6 brand-info">
            <img
              class="img-fluid brand-logo"
              src="/images/1674564713-logo-1-png.png"
            />
            <p>
              Whirlpool je kompanija sa istorijom dugom više od 100 godina,
              poznata pre svega po proizvodnji kuhinjskih uređaja i mašina za
              veš.
            </p>

            <p>
              <br />
              Asortiman Whirlpool-a nudi uređaje za prečišćavanje vazduha koji
              svojim kvalitetom obezbeđuju svežinu i čistoću vazduha dobijenu
              zahvaljujući posebnoj antibakterijskoj tehnologiji koja eliminiše
              99,99% klica i bakterija.
            </p>

            <Link to="/brend-detaljno/5">
              <button type="button" class="brands-custom-button" data-id="1">
                Saznaj više{" "}
                <img class="img-fluid" src="/images/arrow_right.png" />
              </button>
            </Link>
          </div>
        </div>
        <div class="row brands-holder">
          <img
            class="img-fluid brand-img desktop-brand-image-main"
            alt="xiaomi"
            src="/images/1674565538-baner-1-1-png.png"
          />
          <img
            class="img-fluid brand-img mobile-brand-image-main"
            alt="xiaomi"
            src="/images/1674567291-mobilni-1-png.png"
          />
          <div class="col-sm-6 brand-info">
            <img
              class="img-fluid brand-logo"
              src="/images/1674565538-c-png.png"
            />
            <p>
              <br />
              Kärcher, kompanija koja je nastala pre više 80 godina i od
              porodičnog preduzeća se razvila u svetski priznat i poznat brend,
              važi za vodećeg proizvođača efikasnih sistema za čišćenje.
            </p>

            <p>
              Kärcher prečišćivači osiguravaju da vazduh u prostoriji bude svež,
              filtriraju alergene kao što su prašina i polen i efikasno
              eliminišu viruse i neprijatne mirise iz vazduha.
            </p>

            <Link to="/brend-detaljno/6">
              <button type="button" class="brands-custom-button" data-id="1">
                Saznaj više{" "}
                <img class="img-fluid" src="/images/arrow_right.png" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Brands;
