import React from "react";
import Button from "../../components/Site/Reusable/stari/Button";
import { baseUrl } from "../../utils";
import { Link } from "react-router-dom";

const BlogItem = (props) => {
  const { item } = props || {};
  return (
    <div class="col-sm-6">
      <Link
        to={`/blog/blog-detaljno/${item.anId}`}
        style={{ textDecoration: "none" }}
      >
        <div class="blog-item-homepage">
          <div class="blog-item-homepage-image">
            <img
              src={baseUrl + `images/${item.acImageSmall}`}
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="blog-item-homepage-body">
            <h3 class="subtitle-blog-item-homepage">{item.acSubtitle}</h3>
            <h2 class="title-blog-item-homepage">{item.acTitle}</h2>
            <button class="clear-btn-detail">
              Saznajte više <img src="/images/desno.svg" alt="" />
            </button>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BlogItem;
