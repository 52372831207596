import React, { useState } from "react";
import { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/stari/HelmetSeo";
import ContactForm from "../../components/Site/Contact";

// import "../../assets/karcher/all.min.css";
import "../../assets/karcher/animate.css";
import "../../assets/karcher/jquery.fancybox.css";
import "../../assets/karcher/owl.carousel.min.css";
import "../../assets/karcher/owl.theme.default.min.css";
import "../../assets/karcher/style.css";

const Karcher = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showSpec, setShowSpec] = useState(false);

  const [sliderValue, setSliderValue] = useState(50);

  const moveDivisor = (event) => {
    const value = event.target.value;
    setSliderValue(value);
  };

  const [isplata, setIsplata] = useState(0);

  const initialState = {
    litre: "",
    cena: "",
  };

  let isplataInvesticije = 0;

  const [formData, setFormData] = useState(initialState);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const submitFormCalculatro = () => {
    if (
      isNaN(formData.litre) ||
      isNaN(formData.cena) ||
      formData.litre === "" ||
      formData.cena === ""
    ) {
      alert("Unesite validnu vrednost za konzumaciju.");
      return; // Prekida izvršenje funkcije ako vrednosti nisu validne
    }

    let brojDana = 2500 / formData.litre;

    let trosakVode = brojDana * formData.litre * formData.cena;

    setIsplata((38000 / trosakVode) * brojDana);

    setFormData(initialState);
  };

  return (
    <Fragment>
      <HelmetSeo
        title="Karcher | Prečišćivači vode"
        description="Uz kupovinu Kärcher WPC 120 UF filtera za vodu dobijate i besplatnu montažu"
        keywords="Uz kupovinu Kärcher WPC 120 UF filtera za vodu dobijate i besplatnu montažu ( 1 items ) INFO'keywords' meta tag is obsolete"
      />
      <div class="section-1">
        <div class="section-1-image">
          <img class="main-image" src="/images/karcher-1.png" alt="" />
          <img
            class="second-image"
            src="/images/gratis-montaza.png"
            alt="GRATIS MONTAŽA"
          />
          <div class="section-1-image-text">
            <span class="bold">WPC 120 UF </span>
            <span> jedinica za filtriranje vode</span>
          </div>
        </div>
        <div class="section-1-text">
          <div class="section-1-text-flex">
            <img src="/images/logo2.png" alt="Karcher" />
            <h1>
              SVAKA KAP <br /> INSPIRISANA <br /> ČISTOĆOM
            </h1>
            <p>
              Uz kupovinu Kärcher WPC 120 UF jedinice za <br /> filtriranje vode
              dobijate i besplatnu montažu.
            </p>
          </div>
        </div>
      </div>

      <div class="section-22">
        <div class="section-2-image">
          <img src="/images/karcher-second.png" alt="" />
        </div>
        <div class="section-2-text">
          <div class="section-2-text-flex">
            <h2>Šta donosi WPC 120 UF prečišćivač vode</h2>
            <p>
              Kvalitet našeg zdravlja direktno zavisi od kvaliteta vode koju
              pijemo – iz tog razloga Kärcher donosi WPC 120 UF jedinicu za
              filtriranje vode sa snažnim, četvorostepenim sistemom
              prečišćavanja.{" "}
            </p>
            <p>
              Zahvaljujući njemu, ovaj uređaj neutrališe sve čestice bakterija,
              mikroplastike, virusa, kamenca i teških metala koje obično unosimo
              vodom sa česme.
            </p>
            <p>
              Pored neutralisanja zagađivača koji kvare kvalitet pijaće vode,
              jedinica za filtriranje vode pruža i poboljšan ukus uz zadržavanje
              originalnog mineralnog sastava.
            </p>
          </div>
        </div>
      </div>
      <div class="section-3">
        <div class="section-3-grid">
          <div class="section-3-block">
            <img
              class="desktop-section-3"
              src="/images/kapacite-filtera.png"
              alt=""
            />
            <img class="mobile-section-3" src="/images/1-mob.png" alt="" />
          </div>
          <div class="section-3-block">
            <img
              class="desktop-section-3"
              src="/images/protok-vode.png"
              alt=""
            />
            <img class="mobile-section-3" src="/images/2-mob.png" alt="" />
          </div>
          <div class="section-3-block">
            <img
              class="desktop-section-3"
              src="/images/efikasna-filtracija.png"
              alt=""
            />
            <img class="mobile-section-3" src="/images/3-mob.png" alt="" />
          </div>
        </div>
      </div>
      <div class="section-44">
        <div class="section-4-image">
          <img src="/images/karcher-third.png" alt="" />
        </div>
        <div class="section-4-text">
          <h2>Sistem filtera i održavanje</h2>
          <p>
            Dugoročno uživanje u zdravijoj pijaćoj vodi podržano je kapacitetom
            filtera od 2.500 l – što je više nego dovoljno za konzumiranje
            zdrave pijaće vode tokom dužeg vremenskog perioda.{" "}
          </p>
          <p>
            Filteri za vodu se mogu zameniti u svega nekoliko minuta – jedan
            okret je dovoljno da se ukloni, a onda je samo potrebno namestiti
            novi i kristalno čista voda je ponovo tu.
          </p>
          <a href="#gdekupiti">
            Pronađite prodajno mesto <i class="fal fa-chevron-right"></i>
          </a>
        </div>
      </div>
      <div class="section-55">
        <h3>Dodatna ušteda uz gratis montažu</h3>

        <p>
          Za razliku od većine prečišćivača za vodu, kod WPC 120 UF jedinice za
          filtriranje vode nema pumpe niti priključaka za struju, što znači da
          nema ni dodatnih troškova.
        </p>

        <p>
          Ovaj uređaj ugrađuje se direktno ispod kuhinjskog sudopera, što znači
          da prečišćena voda ne stoji zarobljena u uređaju gubeći svoju svežinu.
        </p>

        <p>
          Nakon što izvršite kupovinu imate rok do 3 meseca da obavestite
          odgovarajućeg servisera i uz uređaj dobijate i besplatnu montažu –
          dovoljno je samo da pokažete račun.
        </p>

        <a href="#popup" class="show-popup" onClick={() => setShowPopup(true)}>
          Spisak servisera koji vrše instalaciju{" "}
          <i class="fal fa-chevron-right"></i>
        </a>
        <div class={`popup ${showPopup && "show"}`} id="popup">
          <h4>Ovlašćeni servisi</h4>
          <a
            class="closePopup"
            onClick={() => setShowPopup(false)}
            style={{ cursor: "pointer" }}
          >
            X
          </a>
          <div class="popup-lists">
            <div class="popup-list">
              <span>Trgovac:</span>
              <ul>
                <li>Confix d.o.o.</li>
                <li>Kärcher Center Euro Original d.o.o.</li>
                <li>Kärcher Center Keller d.o.o.</li>
                <li>Kärcher Center Mlazmatik d.o.o.</li>
                <li>Nova Oprema d.o.o. Isključivo za Komunalni program</li>
                <li>Original Com d.o.o.</li>
                <li>Kärcher Store Pegaz Centar d.o.o.</li>
                <li>A-K d.o.o.</li>
              </ul>
            </div>
            <div class="popup-list">
              <span>Adresa:</span>
              <ul>
                <li> Ulica Nova 20. br.17. 21203 Veternik - Novi Sad</li>
                <li>Ivana Milutinovića 81 b11211 Beograd-Borča</li>
                <li>Bulevar Nikole Tesle bb 18000 Niš</li>
                <li>Maršala Tita b.b. 26212 Kačarevo</li>
                <li>Bulevar vojvode Stepe 521 000 Novi Sad</li>
                <li>Gandijeva 126 11070 Novi Beograd</li>
                <li>Primorska 58 21000 Novi Sad</li>
                <li>Srdan llije 11 15000 Šabac</li>
              </ul>
            </div>
            <div class="popup-list">
              <span>Kontakt:</span>
              <ul>
                <li>
                  Tel:<a href="+38121820734"> (00381) 21/ 820 734</a>
                </li>
                <li>
                  Tel:<a href="+381112087787"> (00381) 11/ 208 7787</a>
                </li>
                <li>
                  Tel:<a href="+38118588260"> (00381) 18 588 260</a>
                </li>
                <li>
                  Tel:<a href="+38113602110"> (00381) 13/ 602 110</a>
                </li>
                <li>
                  Tel:<a href="+381216616650"> (00381) 21 661 6650</a>
                </li>
                <li>
                  Tel:<a href="+381113181467"> (00381) 11/ 318 14 67</a>
                </li>
                <li>
                  Tel:<a href="+381216417057"> (00381) 21/64 17 057</a>
                </li>
                <li>
                  Tel:<a href="+38115335940"> (00381)15/335 940</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="section-6">
        <div class="section-6-image">
          <img src="/images/karcher-fourth.png" alt="" />
        </div>
        <div class="section-6-text">
          <div class="calculator">
            <span class="calculator-title">Kalkulator uštede</span>
            <div class="calculator-form">
              <div class="calculator-input" id="konzumacija">
                <span>Konzumacija u litrima</span>{" "}
                <input
                  type="text"
                  placeholder="Unesite litre"
                  name="litre"
                  value={formData.litre}
                  onChange={handleInputChange}
                />
              </div>
              <div class="calculator-input" id="litri">
                <span>Cena za litar vode</span>{" "}
                <input
                  type="text"
                  placeholder="Unesite cenu"
                  value={formData.cena}
                  name="cena"
                  onChange={handleInputChange}
                />
              </div>

              <button onClick={() => submitFormCalculatro()}>Izračunaj</button>
              <div class="calculator-result">
                <span class="name"></span>
                <span class="value">
                  {Math.round(isplata) > 0 && (
                    <>
                      <span class="name">Isplata investicije</span>
                      <span class="result">{Math.round(isplata)} dana</span>
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
          <p>
            Unesite svoju dnevnu konzumaciju vode u litrima i cenu vode koju
            plaćate po litru kako biste izračunali za koliko će vam se kupovina
            WPC 120 UF jedinice za filtriranje vode isplatiti.
          </p>
        </div>
      </div>
      <div class="section-77">
        <h2>Kako funkcioniše filtracija?</h2>
        <div class="section-7-grid">
          <div class="section-7-block">
            <img src="/images/i-ii-stepen.png" alt="" />
            <div class="section-7-block-text">
              <span>Pre-Pure Filter </span>
              <p>
                Filter za čestice i filter sa aktivnim ugljem uklanjaju velike
                suspendovane materije iz vode.
              </p>
            </div>
          </div>
          <div class="section-7-block">
            <img src="/images/iii-stepen.png" alt="" />
            <div class="section-7-block-text">
              <span>Hy-Protect filter </span>
              <p>
                Ultrafiltraciona membrana uklanja čak i sitne čestice veličine
                0,1 mikrona i tako zadržava viruse i više od 99,99% bakterija.
              </p>
            </div>
          </div>
          <div class="section-7-block">
            <img src="/images/iv-stepen.png" alt="" />
            <div class="section-7-block-text">
              <span>Post-protect filter </span>
              <p>
                {" "}
                Filter sa aktivnim ugljem filtera zadržava hlor, ostatke
                hemijskih čestica i teške metale i poboljšava ukus vode bez
                uticaja na njen sadržaj.
              </p>
            </div>
          </div>
        </div>
        <a href="#!" class="showToggle" onClick={() => setShowSpec(!showSpec)}>
          Kompletna specifikacija uređaja
        </a>
        <div class={`toggle ${!showSpec && "hide-spec"}`}>
          <ul>
            <li>
              <span class="name">Ulazni pritisak vode (bar)</span>{" "}
              <span class="value">1-4</span>{" "}
            </li>
            <li>
              <span class="name">Priključak za vodu</span>{" "}
              <span class="value">3/8″</span>
            </li>
            <li>
              <span class="name">Kapacitet filtera (I)</span>{" "}
              <span class="value">2500</span>
            </li>
            <li>
              <span class="name">Max. ulazna temperatura vode (°C )</span>{" "}
              <span class="value">5 – 38</span>
            </li>
            <li>
              <span class="name">Protok (l/h)</span>{" "}
              <span class="value">120</span>
            </li>
            <li>
              <span class="name">Težina bez pribora (kg)</span>{" "}
              <span class="value">5,7</span>
            </li>
            <li>
              <span class="name">Težina sa priborom (kg)</span>{" "}
              <span class="value">8</span>
            </li>
            <li>
              <span class="name">Dimenzije (D x Š x V) (mm)</span>{" "}
              <span class="value">377 x 168 x 421</span>
            </li>
          </ul>
        </div>
      </div>

      <div id="comparison">
        <figure>
          <div id="divisor" style={{ width: `${sliderValue}%` }}>
            <img src="/images/slajder.png" alt="" />
          </div>
        </figure>
        <input
          type="range"
          min="0"
          max="100"
          value={sliderValue}
          onChange={moveDivisor}
        />
        <img
          id="arrow"
          src="/images/slajder.png"
          alt=""
          style={{ display: "none" }}
        />
      </div>

      <div id="gdekupiti" class="section-9">
        <div class="section-9-text">
          <h3>Gde ga možete kupiti?</h3>
          <p>
            WPC 120 UF jedinicu za filtriranje vode možete pronaći u svim
            maloprodajnim objektima, kao i na online prodavnicama koje možete
            videti ispod:
          </p>
        </div>
        <div class="section-9-grid-wrapper">
          <div class="section-9-grid">
            <a href="#!">
              <img src="/images/ananas.png" alt="" />
            </a>
            <a href="#!">
              <img src="/images/shoppster 5.png" alt="" />
            </a>
            <a href="#!">
              <img src="/images/bcg-logo-wtl.png" alt="" />
            </a>
            <a href="#!">
              <img src="/images/bazzar 4.png" alt="" />
            </a>
            <a href="#!">
              <img src="/images/tempo.png" alt="" />
            </a>
            <a href="#!" class="cursor">
              <img src="/images/metalac.svg" alt="" />
            </a>
            <a href="#!" class="cursor">
              <img src="/images/donic-logo-krajnji500x250.png" alt="" />
            </a>
            <a href="#!" class="cursor">
              <img src="/images/kondor.png" alt="" />
            </a>
            <a href="#!" class="cursor">
              <img src="/images/stefan.png" alt="" />
            </a>
            <a href="#!" class="cursor">
              <img src="/images/metal-flix.webp" alt="" />
            </a>
            <a href="#!" class="cursor">
              <img src="/images/magnotron 4.png" alt="" />
            </a>
            <a href="#!" class="cursor">
              <img src="/images/asproduct 1.png" alt="" />
            </a>
            <a href="#!" class="cursor">
              <h3>EXPERT D.O.O LESKOVAC</h3>
            </a>
          </div>
        </div>
      </div>
      <div class="section-10">
        <p>
          Usluga gratis montaže navedenog proizvoda odnosi se samo na kupovinu
          na teritoriji Republike Srbije i zahteva posedovanje fiskalnog računa.
        </p>
      </div>
    </Fragment>
  );
};

export default Karcher;
