import React from "react";
import Contact from "../../components/Site/Contact";

const ErrorPage = () => {
  return (
    <div className="container-fluid  error-page-container">
      <div className="row1">
        <div className="container">
          <div className="error-page-block">
            <img
              src="/images/error404.png"
              alt="Relog Media"
              className="img-fluid"
            />
            <div className="error-page-block-title">
              STRANICA NIJE PRONAĐENA
            </div>
          </div>
          <Contact />
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
