import React, { useEffect } from "react";
import { Fragment } from "react";
import Breadcrumb from "../../components/Admin/Reusable/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import InputField from "../../components/Admin/Reusable/InputField";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { isEmpty } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { basicFormValidationHandler } from "../../utils";
import {
  fetchUserPagesList,
  fetchCreateNewUser,
  fetchEditUser,
} from "../../actions/Admin/user";
import { useDispatch, useSelector } from "react-redux";
import KeyIcon from "@mui/icons-material/Key";
import { fetchRolePagesList } from "../../actions/Admin/role";

function generateRandomPassword() {
  const length = Math.floor(Math.random() * 3) + 13; // Dužina između 9 i 11 karaktera
  const specialChars = "-_~.#!@&";

  const allChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789" +
    specialChars;

  const getRandomChar = (charSet) => {
    const randomIndex = Math.floor(Math.random() * charSet.length);
    return charSet.charAt(randomIndex);
  };

  let password = "";
  password += getRandomChar("ABCDEFGHIJKLMNOPQRSTUVWXYZ"); // Prvo slovo je veliko
  password += getRandomChar("0123456789"); // Barem jedan broj
  password += getRandomChar(specialChars); // Barem jedan specijalni karakter

  while (password.length < length) {
    password += getRandomChar(allChars);
  }

  return password;
}

const User = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dataPagesDetail } = useSelector((state) => state.userReducer);
  const { dataPagesList } = useSelector((state) => state.roleReducer);

  const initialState = {
    acFirstName: "",
    acLastName: "",
    acEmail: "",
    acPhone: "",
    acUsername: "",
    acPassword: "",
    anStatus: 1,
    anRoleId: 1,
  };

  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) {
      dispatch(fetchUserPagesList(id));
    }
    dispatch(fetchRolePagesList());
  }, [id, dispatch]);

  useEffect(() => {
    if (dataPagesDetail && Object.keys(dataPagesDetail).length > 0) {
      setData(dataPagesDetail);
      setData((prevUser) => ({
        ...prevUser,
        acPassword: "",
      }));
    }
  }, [dataPagesDetail]);

  const userValidationHandler = () => {
    const { acFirstName, acLastName, acEmail, acUsername, acPassword } = data;

    let errorsObject = {};
    if (id) {
      errorsObject = basicFormValidationHandler({
        acFirstName,
        acLastName,
        acEmail,
        acUsername,
      });
    } else {
      errorsObject = basicFormValidationHandler({
        acFirstName,
        acLastName,
        acEmail,
        acUsername,
        acPassword,
      });
    }

    setErrors(errorsObject);
    if (!isEmpty(errorsObject))
      toast.error("Morate popuniti sva obavezna polja", { theme: "colored" });
    return !isEmpty(errorsObject);
  };

  const handleGeneratePassword = () => {
    const newPassword = generateRandomPassword();
    setData((prevUser) => ({
      ...prevUser,
      acPassword: newPassword,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (userValidationHandler()) {
      return;
    }

    const formData = new FormData();

    formData.append("acFirstName", data.acFirstName);
    formData.append("acLastName", data.acLastName);
    formData.append("acEmail", data.acEmail);
    formData.append("anRoleId", data.anRoleId);
    formData.append("anStatus", data.anStatus);
    formData.append("acPassword", data.acPassword);
    formData.append("acUsername", data.acUsername);
    formData.append("acPhone", data.acPhone);

    if (id) {
      formData.append("anId", id);
      dispatch(fetchEditUser(data, navigate));
    } else {
      dispatch(fetchCreateNewUser(data, navigate));
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="Korisnici" type="user-forma" />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h1 className="card-title mb-0 flex-grow-1">
                {/* Kreiranje novog pitanja */}
                {id ? `Izmena korisnika` : "Kreiranje novog korisnika"}
              </h1>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit} noValidate>
                <div className="row">
                  <div className="col-6">
                    <InputField
                      required
                      label="Ime"
                      name="acFirstName"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acFirstName}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />
                    <InputField
                      required
                      label="Prezime"
                      name="acLastName"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acLastName}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />
                    <InputField
                      required
                      label="Email"
                      name="acEmail"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acEmail}
                      onChange={handleInputChange}
                      fullWidth
                      type="email"
                      size="small"
                      className="mb-4"
                    />
                    <InputField
                      label="Telefon"
                      name="acPhone"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acPhone}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />
                    <FormControl fullWidth className="mb-4">
                      <InputLabel id="demo-simple-select-label">
                        Rola
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.anRoleId}
                        label="Rola"
                        name="anRoleId"
                        onChange={handleInputChange}
                        size="small"
                      >
                        {dataPagesList?.map((item, key) => (
                          <MenuItem value={item.anId} key={key}>
                            {item.acName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-6">
                    <InputField
                      required
                      label="Korisničko ime"
                      name="acUsername"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acUsername}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />
                    <div className="row">
                      <div className="col-4">
                        <Button
                          className="generate-password-input w-100"
                          variant="contained"
                          color="primary"
                          startIcon={<KeyIcon />}
                          onClick={() => handleGeneratePassword()}
                        >
                          Generiši lozinku
                        </Button>
                      </div>
                      <div className="col-8">
                        <InputField
                          required
                          label="Lozinka"
                          name="acPassword"
                          error={errors}
                          errorsHandler={setErrors}
                          value={data.acPassword}
                          onChange={handleInputChange}
                          fullWidth
                          size="small"
                          className="mb-4"
                          disabled
                        />
                      </div>
                    </div>
                    <FormControl fullWidth className="mb-4">
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.anStatus}
                        label="Status"
                        name="anStatus"
                        onChange={handleInputChange}
                        size="small"
                      >
                        <MenuItem value="1">Aktivan</MenuItem>
                        <MenuItem value="0">Neaktivan</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="float-end mt-3">
                  {id ? (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<CheckIcon />}
                    >
                      Potvrda izmene
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<AddIcon />}
                    >
                      Kreiraj
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default User;
