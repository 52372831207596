import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useState } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroNewsSlider = () => {
  const [selected, setSelected] = useState(1);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
  };
  return (
    <div class="container1">
      <div>
        <Slider {...settings}>
          <div className="carousel-cell-11">
            <Link to="/karcher-preciscivaci">
              <img
                src="/images/banner-1.jpg"
                alt="Prečišćivači"
                className="img-fluid img-fluid desktop-brand-image-main"
              />
              <img
                src="/images/1704887210-Karcher-390-x-566-jpg.jpg"
                alt="Prečišćivači"
                class="img-fluid mobile-brand-image-main"
              />
            </Link>
          </div>
          <div className="carousel-cell-22">
            <Link to="/brend-detaljno/1">
              <img
                src="/images/banner-2.jpg"
                alt="Prečišćivači"
                className="img-fluid desktop-brand-image-main"
              />
              <img
                src="/images/1704886333-Sharp-390-x-566-jpg.jpg"
                alt="Prečišćivači"
                class="img-fluid mobile-brand-image-main"
              />
            </Link>
          </div>
          <div className="carousel-cell-33">
            <Link to="/proizvodi">
              <img
                src="/images/banner-3.jpg"
                alt="Prečišćivači"
                className="img-fluid desktop-brand-image-main"
              />
              <img
                src="/images/1669999498-GLAVNI-BANER-mob-jpg.jpg"
                alt="Prečišćivači"
                class="img-fluid mobile-brand-image-main"
              />
            </Link>
          </div>
          <div className="carousel-cell-44">
            <Link to="/proizvod/detaljno/57">
              <img
                src="/images/banner-4.jpg"
                alt="Prečišćivači"
                className="img-fluid desktop-brand-image-main"
              />
              <img
                src="/images/1669999300-baner-4-mob-jpg.jpg"
                alt="Prečišćivači"
                class="img-fluid mobile-brand-image-main"
              />
            </Link>
          </div>
          <div className="carousel-cell-55">
            <Link to="/proizvod/detaljno/43">
              <img
                src="/images/banner-5.jpg"
                alt="Prečišćivači"
                className="img-fluid desktop-brand-image-main"
              />
              <img
                src="/images/1669999235-baner-5-mob-jpg.jpg"
                alt="Prečišćivači"
                class="img-fluid mobile-brand-image-main"
              />
            </Link>
          </div>
          <div className="carousel-cell-66">
            <Link to="/proizvod/detaljno/53">
              <img
                src="/images/banner-6.jpg"
                alt="Prečišćivači"
                className="img-fluid desktop-brand-image-main"
              />
              <img
                src="/images/1669999143-baner-6-mob-(1)-jpg.jpg"
                alt="Prečišćivači"
                class="img-fluid mobile-brand-image-main"
              />
            </Link>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default HeroNewsSlider;
