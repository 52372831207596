import React from "react";
import { Fragment } from "react";

import LeftSidebar from "../../../components/Admin/Layout/LeftSidebar";
import Header from "../../../components/Admin/Layout/Header";
import Footer from "../../../components/Admin/Layout/Footer";

import "../../../assets/Admin/global.scss";

const ThemeWrapperAdmin = (props) => {
    return (
        <Fragment>
            <LeftSidebar />
            <Header />
            <div className="main-content-conteiner">
                <div className="container-fluid">
                    <div className="page-content">{props.children}</div>
                    <Footer />
                </div>
            </div>
        </Fragment>
    );
};

export default ThemeWrapperAdmin;
