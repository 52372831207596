import React, { useState } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Navigation = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [showMenuMob, setShowMenuMob] = useState(false);

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <header id="header">
      <div class="container">
        <div class="row">
          <div class="desktop-header">
            <div class="d-flex flex-row">
              <div class="item-header-logo">
                <Link to="/">
                  <img
                    src="/images/logo.png"
                    alt="Prečišćivači vazduha"
                    class="image-logo-item"
                  />
                </Link>
              </div>
              <div class="item-header-list">
                <ul>
                  <Link
                    to="/proizvodi"
                    class={`header-item-link ${
                      currentPath == "/proizvodi" && "active"
                    }`}
                    onMouseEnter={() => setShowMenu(true)}
                    onMouseLeave={() => setShowMenu(false)}
                  >
                    <div class="header-menu-submenu">
                      <li class="header-item">Proizvodi</li>

                      <div class={`sub-menu ${showMenu && "active"}`}>
                        <div class="sub-menu-items">
                          <ul>
                            <li>
                              <Link to="/proizvodi">Prečišćivači vazduha</Link>
                            </li>
                            <li>
                              <Link to="/karcher-preciscivaci">
                                Prečišćivači vode
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="/brendovi"
                    class={`header-item-link ${
                      currentPath == "/brendovi" && "active"
                    }`}
                  >
                    <li class="header-item">Brendovi</li>
                  </Link>
                  <Link
                    to="/gde-kupiti"
                    class={`header-item-link ${
                      currentPath == "/gde-kupiti" && "active"
                    }`}
                  >
                    <li class="header-item">Gde kupiti</li>
                  </Link>
                  <Link
                    to="/registracija"
                    class={`header-item-link ${
                      currentPath == "/registracija" && "active"
                    }`}
                  >
                    <li class="header-item">Registracija proizvoda</li>
                  </Link>
                  <Link
                    to="/blog"
                    class={`header-item-link ${
                      currentPath == "/blog" && "active"
                    }`}
                  >
                    <li class="header-item">Blog</li>
                  </Link>
                  <Link
                    to="/kontakt"
                    class={`header-item-link ${
                      currentPath == "/kontakt" && "active"
                    }`}
                  >
                    <li class="header-item">Kontakt</li>
                  </Link>
                  <li>
                    <img
                      src="/images/search-icon.png"
                      alt="Prečišćivači vazduha pretraga"
                      class="showSearchModal"
                    />
                    <input
                      type="text"
                      name="search-input"
                      id="search-input"
                      class="input-search-box d-none"
                      placeholder="Pretraži"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="mobile-header">
            <div class="content-mobile-header">
              <div
                class={`flex-1 menu-mobile-header ${showMenuMob && "active"}`}
              >
                <img
                  src="/images/menu.png"
                  alt=""
                  onClick={() => setShowMenuMob(!showMenuMob)}
                />
              </div>
              <div class="flex-1 logo-mobile-header">
                <Link to="/">
                  <img
                    src="/images/logo.png"
                    alt="Prečišćivači vazduha"
                    class="image-logo-item"
                  />
                </Link>
              </div>
              <div class="flex-1 search-mobile-header">
                <img
                  src="/images/search-icon.png"
                  alt="Prečišćivači vazduha pretraga"
                  class="showSearchModalMobile"
                />
                <input
                  type="text"
                  name="search-input-mobile"
                  id="search-input-mobile"
                  class="input-search-box d-none"
                  placeholder="Pretraži"
                />
              </div>

              <div class={`mobiel-header-modal ${showMenuMob && "active"}`}>
                <ul>
                  <Link to="/proizvodi" onClick={() => setShowMenuMob(false)}>
                    <li>Prečišćivači vazduha</li>
                  </Link>
                  <Link
                    to="/karcher-preciscivaci"
                    onClick={() => setShowMenuMob(false)}
                  >
                    <li>Prečišćivači vode</li>
                  </Link>
                  <Link to="/brendovi" onClick={() => setShowMenuMob(false)}>
                    <li>Brendovi</li>
                  </Link>
                  <Link to="/gde-kupiti" onClick={() => setShowMenuMob(false)}>
                    <li>Gde kupiti</li>
                  </Link>
                  <Link
                    to="/registracija"
                    onClick={() => setShowMenuMob(false)}
                  >
                    <li>Registracija proizvoda</li>
                  </Link>
                  <Link to="/blog" onClick={() => setShowMenuMob(false)}>
                    <li>Blog</li>
                  </Link>
                  <Link to="/kontakt" onClick={() => setShowMenuMob(false)}>
                    <li>Kontakt</li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navigation;
