import React, { Fragment, useEffect } from "react";
import AggridTable from "../../components/Admin/Reusable/AggridTable";
import Breadcrumb from "../../components/Admin/Reusable/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { deleteSeoPage, fetchSeoPagesList } from "../../actions/Admin/seo";
import ButtonColumn from "../../components/Admin/Reusable/ButtonColumn";

import LoadingIndicator from "../../components/Admin/Reusable/LoadingIndicator";

const SeoPagesList = () => {
  const dispatch = useDispatch();

  const { seoPagesList, loading } = useSelector((state) => state.seoReducer);

  useEffect(() => {
    dispatch(fetchSeoPagesList());
  }, [dispatch]);

  const handleSubmitModal = (pageId) => {
    dispatch(deleteSeoPage(pageId));
  };

  const customCellRenderer = (params) => (
    <ButtonColumn
      editLink={`/adminManager/seo/pages/form/` + params.data.anId}
      value={params}
      submitActionModal={handleSubmitModal}
    />
  );

  //settings za tabelu
  const columnDefs = [
    { headerName: "Id", field: "anId", filter: true, width: 100 },
    { headerName: "Strana", field: "acName", filter: "agTextColumnFilter" },
    { headerName: "Naslov", field: "acTitle", filter: "agTextColumnFilter" },
    {
      headerName: "Keywords",
      field: "acKeywords",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Opis",
      field: "acDescription",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Datum kreiranja",
      field: "adTimeIns",
      filter: "agDateColumnFilter",
    },
    {
      headerName: "Datum izmene",
      field: "adTimeEdit",
      filter: "agDateColumnFilter",
    },
    {
      headerName: "Kreirao",
      field: "ins_acUsername",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Izmenio",
      field: "edit_acUsername",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Panel",
      field: "custom",
      cellRenderer: customCellRenderer,
      pinned: "right",
      width: 120,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb title="Seo stranice" type="seo-stranice" />
      <AggridTable rowData={seoPagesList} columnDefs={columnDefs} />
      <LoadingIndicator loading={loading} />
    </Fragment>
  );
};

export default SeoPagesList;
