import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const flex = 1;
  const color = "fff !important";

  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    const isButtonClicked = localStorage.getItem("buttonClicked");
    if (isButtonClicked) {
      setButtonClicked(true);
    }
  }, []);

  const handleClick = () => {
    setButtonClicked(true);
    localStorage.setItem("buttonClicked", "true");
  };

  return (
    <footer>
      {!buttonClicked && (
        <div class="cookie-holder-block show">
          <div class="container">
            <div class="d-flex">
              <div class="left-side-cookie" style={{ flex }}>
                Koristimo kolačiće (cookies) kako bismo unapredili vaše
                korisničko iskustvo.
                <br /> Korišćenjem sajta slažete se sa{" "}
                <Link to="/politika-privatnosti" style={{ color }}>
                  Politikom privatnosti
                </Link>
                .
              </div>
              <div class="right-side-cookie">
                <button
                  class="btn-info-white-border hover-btn-home cookieAction"
                  onClick={handleClick}
                >
                  Prihvatam
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div class="container">
        <div class="row row-mobile-column">
          <div class="col">
            <ul class="footer-left-links">
              <Link to="/politika-privatnosti">
                <li>POLITIKA PRIVATNOSTI</li>
              </Link>
              <Link to="/politika-upotrebe-kolacica">
                <li>POLITIKA UPOTREBE KOLAČIĆA</li>
              </Link>
              <Link to="/registration">
                <li>REGISTRACIJA KUPOVINE</li>
              </Link>
              <li class="footer-special-link">
                Sva prava zadržana. © 2022. List Computers D.O.O.
              </li>
            </ul>
          </div>
          <div class="col">
            <div class="content-right-footer-holder float-end">
              <h5 class="footer-firm-name">List Computers D.O.O.</h5>
              <ul class="footer-left-links">
                <li>
                  <img
                    src="/images/drop-outline.png?ver={time()}"
                    class="img-fluid"
                  />
                  &nbsp;Cara Dušana 212, 11080 Zemun, Beograd
                </li>
                <li class="hover-item-footer">
                  <Link to="mailto:office@list.rs">
                    <img
                      src="/images/mail-outline.png?ver={time()}"
                      class="img-fluid"
                    />
                    &nbsp;office@list.rs
                  </Link>
                </li>
                <li class="hover-item-footer">
                  <Link to="tel:+381113535555">
                    <img
                      src="/images/phone-outline.png?ver={time()}"
                      class="img-fluid"
                    />
                    &nbsp;+381 11 35-35-555
                  </Link>
                </li>
                <li class="hover-item-footer">
                  <Link to="tel:+38163389415">
                    <img
                      src="/images/phone-outline.png?ver={time()}"
                      class="img-fluid"
                    />
                    &nbsp;+381 63 389-415
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col footer-special-link-mobile">
            <ul class="footer-left-links">
              <li class="footer-special-link-mobile-text">
                Sva prava zadržana. © 2022. Ewe Comp D.O.O.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
