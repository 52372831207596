import React from "react";
import { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/stari/HelmetSeo";
import ContactForm from "../../components/Site/Contact";
import { Link, useParams } from "react-router-dom";

const BrandsDetail = () => {
  const { id } = useParams();

  const data = [
    {
      id: 1,
      main: "Sharp",
      logo: "sharp-logo.png",
      title: "Profesionalnost oslonjena na tradiciju",
      text: "Sa tradicijom dugom 110 godina, Sharp je japanski brend koji inovativnim i originalnim pristupom stvara električne uređaje, televizore, audio, električne trotinete i prečišćivače vazduha, koji su osvojili svetsko tržište.<br/><br/> Polazeći od svoje vizije koja se zasniva na iskrenosti i kreativnosti Sharp se trudi da svoje pronalaske usmeri ka unapređivanju svakodnevice svojih kupaca čineći užitak u svakom novom danu lepšim i prijatnijim.<br/><br/> Uz posvećenost koja podrazumeva drugačiji pristup u pronalaženju novih tehnoloških rešenja ova kompanija donosi jednostavnost podržanu naprednošću i kvalitetom koji inspiriše",
      link: "/proizvodi?Brendovi=3&",
      yt1: "d5HxRqR4iGM",
      yt2: "9gPSzOvCO08",
      title2: "SPECIFIKACIJE PREČIŠĆIVAČA",
      text2:
        "Sharp asortiman proizvoda nudi veliki izbor prečišćivača vazduha, različitih kapaciteta i dimenzija, koji su pogodni za prostorije od 4 do 68 metara kvadratnih. <br />Sharp prečišćivač vazduha je poseban na tržištu zahvaljujući inovativnoj Plasmacluster tehnologiji jonizacije vazduha koja emitovanjem pozitivnih i negativnih jona filtrira vazduh, uklanja neprijatne mirise, smanjuje statički elektricitet i pomaže održavanju hidratacije kože. <br/><br/>Sharp prečišćivač i ovlaživač vazduha uklanja bakterije i alergene iz vazduha, a posebno je pogodan za osobe sklone alergijama i respiratornim problemima. HEPA filter eliminiše i do 99, 7 % bakterija i čestica iz vazduha, veličine do 0,3 mikrona, a njegove dodatne prednosti uključuju rok trajanja od 10 godina i smanjenu potrošnju električne energije.",
      image1: "1663159759-SHARP-UA-HG60E-LS01-jpg.jpg",
      image2: "1663159776-SHARP-UA-PG50E-WS01-jpg.jpg",
      image3: "1663159787-UA-KIN50E-W-SHARP-jpg.jpg",
    },
    {
      id: 2,
      main: "Sencor",
      logo: "sencor-logo.png",
      title: "U korak sa savremenim dobom",
      text: "Sencor, je japanski brend koji je nastao 1969. godine a 90-tih godina prošlog veka je proširio svoje poslovanje i na evropsko tržište.<br/><br/>Zahvaljujući konstantnom radu na širenju i aktualizaciji asortimana, koji trenutno sadrži više od hiljadu artikala, od televizora, audio uređaja, kuhinjskih aparata i prečišćivača vazduha, Sencor je postao jedan od vodećih tehnoloških brendova.",
      link: "/proizvodi?Brendovi=2&",
      yt1: "TvteJED3rJk",
      yt2: "",
      title2: "SPECIFIKACIJE SENCOR PREČIŠĆIVAČA",
      text2:
        "Sencor prečišćivači vazduha pružaju efikasno rešenje za poboljšanje kvaliteta života. Asortiman sadrži prečišćivač vazduha sa trostrukim, četvorostrukim ili osmostrukim nivoom filtracije vazduha, kao i prečišćivač i ovlaživač vazduha u jednom.<br/><br/>Sa funkcijama jonizacije vazduha i UV svetlom, postižete maksimalnu svežinu vazduha, zahvaljujući emisiji pozitivnih i negativnih jona koji filtracijom čestica iz vazduha<br/><br/>otklanjaju i do 99,92 % štetnih materija, virusa, bakterija i buđi iz vazduha. Uz pomoć HEPA filtera Sencor prečišćivač vazduha hvata čestice prašine, dima, neprijatnih mirisa, gasova i alergena, a zahvaljujući Ugljeničnom filteru apsorbuje benzol, formaldehid i druge štetne gasove.<br/><br/>Prednost Sencor prečišćivača vazduha u odnosu na druge jeste što uz pravilno rukovanje ne zahteva promenu filtera i do 10 godina aktivnog korišćenja.",
      image1: "1663143410-SENCOR-SHA-6400WH-jpg.jpg",
      image2: "1663143423-SENCOR-SHA-8400WH-EUE3-jpg.jpg",
      image3: "1675431223-Sencor-Korekcija-jpg.jpg",
    },
    {
      id: 3,
      main: "Beko",
      logo: "1668500469-Beko_logo-png.png",
      title: "Prepoznatljiv kvalitet kome se veruje",
      text: "Turska kompanija osnovana 1955. godine postala je sinonim za kvalitetne kućne aparate.<br/><br/>Zahvaljujući širokom asortimanu proizvoda bele tehnike, malih i velikih kućnih aparata i prečišćivača vazduha, Beko je danas jedan od tri najznačajnija brenda velikih kućnih aparata u Evropi, i jedan od vodećih svetskih brendova sa prisustvom u 135 zemalja širom sveta",
      link: "/proizvodi?Brendovi=45&",
      yt1: "5eycNH8Jmrw",
      yt2: "kznVx5V4IFw",
      title2: "SPECIFIKACIJE PREČIŠĆIVAČA",
      text2:
        "Beko prečišćivači vazduha pružaju bolji i zdraviji kvalitet života, a svi modeli iz asortimana testirani su od strane AIRMID kompanije koju čine stručnjaci u oblasti borbe protiv lošeg zdravlja izazvanog zagađenjem vazduha.<br/><br/>Zahvaljujući naprednoj tehnologiji i četiri nivoa filtracije, Beko prečišćivači vazduha uklanjaju i do 99, 99 % bakterija, čestica i virusa, stvarajući na taj način zdravije okruženje u domu. Njihov kompaktan dizajn omogućava uklapanje u svaki kutak prostorije, a funkcija cirkulacije vazduha od 360 stepeni štiti vaše zdravlje filtracijom polena, prašine i dlaka kućnih ljubimaca.<br/><br/>Jedinstveni senzor u boji pokazuje nivo zagađenosti vazduha i pruža informacije u realnom vremenu, a noćni režim rada omogućava miran san u čistom vazduhu.",
      image1: "1663143013-BEKO-ATP-5100-I-Preciscivac-vazduha-jpg.jpg",
      image2: "1663143100-BEKO-ATP-7100-I-Preciscivac-vazduha-jpg.jpg",
      image3: "1663143119-BEKO-ATP-7100-I-Preciscivac-vazduha-psd-ver2-jpg.jpg",
    },
    {
      id: 4,
      main: "Xiaomi",
      logo: "xiaomi-logo.png",
      title: "Više od deset godina savremenih tehnoloških rešenja",
      text: "Xiaomi je kineska kompanija koja je za nesto vise od deset godina postojanja uspela da osvoji svetsko tržiste i postane jedan od pet vodećih proizvođača mobilnih telefona u svetu.<br/><br/>Kao proizvođač potrosačke elektronike i savremenih resenja poput prečisćivača vazduha koji olaksavaju svakodnevnicu, kompanija Xiaomi ima viziju da stvara pametne i kvalitetne uređaje dostupne svima.",
      link: "/proizvodi?Brendovi=46&",
      yt1: "ZN0fIZqmszU",
      yt2: "",
      title2: "Smart funkcije za jednostavniji život",
      text2:
        "Xiaomi prečisćivači vazduha sadrže tri nivoa filtracije vazduha zahvaljujući HEPA filteru. Prvi nivo podrazumeva filtraciju većih čestica poput prasine, drugi eliminise 99,97 % dima, polena, dlaka kućnih ljubimaca i drugih stetnih čestica, a poslednji nivo sadrži filter sa aktivnim ugljem koji ubija toksične supstance, formaldehid i neprijatne mirise.<br/><br/>Prednosti koje donosi Xiaomi prečisćivač vazduha uključuju pametno upravljanje uz pomoć Mi Home aplikacije koja je dostupna na tabletu i mobilnom uređaju i praćenje stanja vazduha na OLED displeju. Jos jedna prednost prečisćivača vazduha Xiaomi je veća prosečna pokrivenost prostora u odnosu na modele prečisćivača vazduha drugih proizvođača na tržistu.",
      image1: "1663160060-ls-png.png",
      image2: "1663160093-Mi-Air-Purifier-3C-b-png.png",
      image3: "1663160329-Mi-Air-Purifier-3C-png.png",
    },
    {
      id: 5,
      main: "Whirlpool",
      logo: "1674564713-logo-1-png.png",
      title: "Pouzdan proizvođač duže od jednog veka",
      text: "Whirlpool je američka kompanija koja na svetskom tržištu uspešno posluje duže od jednog veka, nudeći svojim korisnicima uređaje koji garantuju sigurnost i kvalitet.<br/><br/>Poznata prvenstveno po proizvodnji kuhinjskih uređaja i mašina za pranje i sušenje veša, kompanija uspešno pruža inovacije i tehnologije koje olakšavaju svakodnevni život, uključujući i prečišćivače vazduha visokog kvaliteta",
      link: "",
      yt1: "ngUpIZ5wqt8",
      yt2: "zAibUOHM6wM",
      title2: "SPECIFIKACIJE PREČIŠĆIVAČA",
      text2:
        "U asortimanu Whirlpool prečišćivača vazduha mogu se pronaći uređaji koji su pogodni za prostorije različitih veličina, od 10 do 50 m².<br/><br/>Inovacija Whirlpool prečišćivača je Whispure™ Quiet sistem, koji nudi najtišu tehnologiju prečišćavanja vazduha visoke efikasnosti. Whirlpool prečišćivači, u zavisnosti od modela, poseduju 3-4 brzine rada, turbo podešavanje a brzo prečišćavanje i režim mirovanja za tihi rad i energije.<br/><br/>Bolji kvalitet života i vazduha Whirlpool prečišćivači obezbeđuju zahvaljujući filterima koji iz vazduha uklanjaju alergene, prašinu, perut, polen, duvanski dim i čestice buđi. Predfilter sa ugljem zadržava neprijatne mirise i čisti vazduh pre nego što stigne do HEPA filtera, koji eliminiše i do 99,97% čestica malih dimenzija.",
      image1: "1675431009-Whirlpool-jpg.jpg",
      image2: "1675431022-Whirlpool-2-jpg.jpg",
      image3: "1675431034-Whirlpool-3-jpg.jpg",
    },
    {
      id: 6,
      main: "Karcher",
      logo: "1674565538-c-png.png",
      title: "Istrajnost u razvoju efikasnih sistema za čišćenje",
      text: "Kompanija Kärcher nastala je pre više od 80 godina i od porodičnog preduzeća postala svetski poznata po rešenjima usmerenim na efikasno čišćenje. Značaj Kärcher kompanije prepoznat je kod kupaca zbog inovacije, kvaliteta i vrhunskog učinka uređaja. Pored poznatih kompresorskih čistača i višenamenskih usisivača, Kärcher u svojoj ponudi ima i prečišćivače vazduha koji doprinose boljem i kvalitetnijem životu.",
      link: "",
      yt1: "RTxxhAx72dw",
      yt2: "Ajc75e9UbSw",
      title2: "SPECIFIKACIJE PREČIŠĆIVAČA",
      text2:
        "Kärcher prečišćivači osiguravaju svežinu vazduha u prostorijama, eliminišući neprijatne mirise, viruse, prašinu i polen. U zavisnosti od modela, uređaji su namenjeni za sobe najmanje kvadrature sve do prostorija veličine 65 m². Zahvaljujući H13 filtraciji i aktivnom uglju, pouzdano uklanjaju patogene bakterije i alergene uz efikasnost filtera od 99,95% za čestice od 0,3µm. Kärcher prečišćivači poseduju funkciju tajmera, pa je uređaj moguće podesiti da radi 2, 4, 6 ili 8 sati, posle čega se sam gasi. Moguće je uključiti automatski režim koji rad uređaja prilagođava nivou zagađenosti vazduha, kao i ultratihi rad.",
      image1: "1675430926-Karche-jpg.jpg",
      image2: "1675430953-Karche-2-jpg.jpg",
      image3: "1675430989-Karche-3-jpg.jpg",
    },
  ];

  const selectedItem = data.find((item) => item.id == id);

  return (
    <Fragment>
      <HelmetSeo
        title={`${selectedItem.main} | Prečišćivači`}
        description="Prečišćivači"
        keywords="Prečišćivači"
      />

      <div class="container-site-holder">
        <div class="container-fluid brands-detail-page"></div>
        <div class="container">
          <div class="row brand-images">
            <img class="img-fluid brand-list-1" src="/images/listic-01.png" />
            <img
              class="img-fluid brand-detail-logo"
              src={`/images/${selectedItem.logo}`}
            />
            <img class="img-fluid brand-list-2" src="/images/listic-02.png" />
            <img class="img-fluid brand-list-3" src="/images/listic-03.png" />
            <div class="brand-detail-info text-center senExtraBold">
              <h2></h2>
              <div class="line-brand-title"></div>
              <h1 class="brand-title senExtraBold">{selectedItem.title}</h1>
            </div>
          </div>
          <div class="row content-brand-detail">
            <p
              style={{ textAlign: "justify" }}
              dangerouslySetInnerHTML={{ __html: selectedItem.text }}
            ></p>

            <p>&nbsp;</p>
            {selectedItem.link && (
              <div style={{ textAlign: "center" }}>
                <Link to={selectedItem.link}>Pogledaj proizvode</Link>
              </div>
            )}

            <p>&nbsp;</p>

            <p>
              <iframe
                frameborder="0"
                height="500"
                scrolling="no"
                src={`https://www.youtube.com/embed/${selectedItem.yt1}`}
                title="YouTube video player"
                width="100%"
              ></iframe>
              {selectedItem.yt2 && (
                <iframe
                  frameborder="0"
                  height="500"
                  scrolling="no"
                  src={`https://www.youtube.com/embed/${selectedItem.yt2}`}
                  title="YouTube video player"
                  width="100%"
                ></iframe>
              )}
            </p>

            <h2 style={{ textAlign: "center" }}>&nbsp;</h2>

            <h2 style={{ textAlign: "center" }}>{selectedItem.title2}</h2>

            <p>&nbsp;</p>

            <p
              style={{ textAlign: "justify" }}
              dangerouslySetInnerHTML={{ __html: selectedItem.text2 }}
            ></p>
          </div>
        </div>
        <div class="container-fluid brands-detail-page">
          <div class="row brand-gallery">
            <div class="col-sm-4">
              <img
                class="img-fluid w-100"
                src={`/images/${selectedItem.image1}`}
              />
            </div>
            <div class="col-sm-4">
              <img
                class="img-fluid w-100"
                src={`/images/${selectedItem.image2}`}
              />
            </div>
            <div class="col-sm-4">
              <img
                class="img-fluid w-100"
                src={`/images/${selectedItem.image3}`}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BrandsDetail;
