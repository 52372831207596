import React, { Fragment } from "react";
import Button from "../../components/Site/Reusable/stari/Button";
import { baseUrl } from "../../utils";
import { Link } from "react-router-dom";
import HelmetSeo from "../../components/Site/Reusable/stari/HelmetSeo";

const ProductItem = (props) => {
  const { item } = props || {};
  return (
    <Fragment>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="col-product-item">
          <div class="col-product-item-detail col-product-item-image">
            <div class="badge-content-list"></div>
            <img
              src={baseUrl + `images${item.acImageProduct}`}
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="col-product-item-detail col-product-item-button">
            <Link to={`/proizvod/detaljno/${item.anId}`}>
              <button class="btn-info-white-border btnDetail">
                Detaljnije{" "}
                <img
                  src="/images/strelica-desno.png"
                  alt=""
                  class="img-strelica"
                />{" "}
                <img
                  src="/images/strelica-desno-hover.png"
                  class="img-hover-strelica"
                />
              </button>
            </Link>
          </div>
          <div class="col-product-item-detail col-product-item-category karlaRegular"></div>
          <div class="col-product-item-detail col-product-item-title karlaBold">
            {item.acBrand} {item.acName}
          </div>
          <div class="col-product-item-detail col-product-item-type green-color">
            {item.acSquare}m²
          </div>
          <div class="col-product-item-detail col-product-item-type green-color">
            {item.acVolume}m³/h
          </div>
          <div class="col-product-item-detail col-product-item-dimension">
            Dimenzije: {item.acDimension}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductItem;
