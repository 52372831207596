import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { basicFormValidationHandler, getCurrentDateTime } from "../../utils";
import { Fragment } from "react";
import Breadcrumb from "../../components/Admin/Reusable/Breadcrumb";
import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import InputField from "../../components/Admin/Reusable/InputField";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor } from "ckeditor5-custom-build/build/ckeditor";
import AddIcon from "@mui/icons-material/Add";
import {
  fetchBlogDetailPagesList,
  fetchCreateNewDetailBlog,
  fetchDeleteBlogTranslation,
  fetchEditTranslationBlog,
} from "../../actions/Admin/blog";
import { useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import { useModalToggle } from "../../hooks/useModalToggle";
import CustomModal from "../../components/Common/Modal/CustomModal";
import { useCallback } from "react";

const BlogDetailAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { anId } = useParams();

  const { dataPagesDetailTranslation } = useSelector(
    (state) => state.blogReducer
  );

  const resetState = useCallback(() => {
    return {
      acTitle: "",
      acSubtitle: "",
      acDescription: "",
      acTags: "",
      anStatus: 1,
      anLanguage: 1,
      adTimePublish: getCurrentDateTime(),
    };
  }, []);

  const initialState = {
    acTitle: "",
    acSubtitle: "",
    acDescription: "",
    acTags: "",
    anStatus: 1,
    anLanguage: 1,
    adTimePublish: getCurrentDateTime(),
  };

  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showModal, toggleModal] = useModalToggle(false);

  const clientValidationHandler = () => {
    const { acTitle, acSubtitle, adTimePublish } = data;
    const errorsObject = basicFormValidationHandler({
      acTitle,
      acSubtitle,
      adTimePublish,
    });
    setErrors(errorsObject);
    if (!isEmpty(errorsObject))
      toast.error("Morate popuniti sva obavezna polja", { theme: "colored" });
    return !isEmpty(errorsObject);
  };

  useEffect(() => {
    if (
      dataPagesDetailTranslation &&
      Object.keys(dataPagesDetailTranslation).length > 0
    ) {
      setData(dataPagesDetailTranslation[0]);
      const tagsList = dataPagesDetailTranslation[0].acTags
        .split(",")
        .filter((tag) => tag.trim() !== "");
      setTags(tagsList);
    }
  }, [dataPagesDetailTranslation]);

  useEffect(() => {
    if (anId) {
      dispatch(fetchBlogDetailPagesList(anId));
    } else {
      setData(resetState());
      setTags([]);
    }
  }, [anId, dispatch, resetState]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (clientValidationHandler()) {
      return;
    }

    const formData = new FormData();

    formData.append("acTitle", data.acTitle);
    formData.append("acSubtitle", data.acSubtitle);
    formData.append("acDescription", data.acDescription);
    formData.append("acTags", tags);
    formData.append("anStatus", data.anStatus);
    formData.append("anLanguage", data.anLanguage);
    formData.append("adTimePublish", data.adTimePublish);

    if (anId) {
      formData.append("anId", anId);
      dispatch(fetchEditTranslationBlog(formData, navigate));
    } else {
      formData.append("anBlogId", id);
      dispatch(fetchCreateNewDetailBlog(formData, navigate));
    }
  };

  const handleRemoveTag = (tag) => {
    const newTags = tags.filter((t) => t !== tag);
    setTags(newTags);
  };

  const modalCloseHandler = () => {
    toggleModal(false);
  };

  //akcija brisanja iz liste
  const submitActionModal = (pageId) => {
    dispatch(fetchDeleteBlogTranslation(pageId, navigate));
  };

  return (
    <Fragment>
      <Breadcrumb title="Blog detalji" type="blog-detail" />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h1 className="card-title mb-0 flex-grow-1">
                Kreiranje novog bloga
              </h1>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit} noValidate>
                <div className="row">
                  <div className="col-4">
                    <FormControl fullWidth className="mb-4">
                      <InputLabel id="demo-simple-select-label">
                        Jezik
                      </InputLabel>
                      <Select
                        disabled={anId ? true : false}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.anLanguage}
                        label="Jezik"
                        name="anLanguage"
                        onChange={handleInputChange}
                        size="small"
                      >
                        <MenuItem value="1">Srpski</MenuItem>
                        <MenuItem value="2">Engleski</MenuItem>
                      </Select>
                    </FormControl>

                    <InputField
                      required
                      label="Naslov"
                      name="acTitle"
                      multiline
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acTitle}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />

                    <InputField
                      required
                      multiline
                      rows={4}
                      label="Podnaslov"
                      name="acSubtitle"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acSubtitle}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />

                    <InputField
                      required
                      label="Datum objave"
                      name="adTimePublish"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.adTimePublish}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                      type="datetime-local"
                      inputLabelProps={{ shrink: true }}
                    />

                    <Autocomplete
                      clearIcon={false}
                      options={[]}
                      freeSolo
                      size={`small`}
                      multiple
                      value={tags}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      onChange={(event, newTags) => {
                        setTags(newTags);
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={index}
                            label={option}
                            {...getTagProps({ index })}
                            onDelete={() => handleRemoveTag(option)}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Tagovi" {...params} />
                      )}
                    />

                    <FormControl fullWidth className="mb-4">
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.anStatus}
                        label="Status"
                        name="anStatus"
                        onChange={handleInputChange}
                        size="small"
                      >
                        <MenuItem value="1">Aktivan</MenuItem>
                        <MenuItem value="0">Neaktivan</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-8">
                    <InputLabel id="demo-simple-select-label" className="mb-2">
                      Opis:
                    </InputLabel>
                    <CKEditor
                      name="acDescription"
                      editor={Editor}
                      data={data.acDescription}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setData((prevUser) => ({
                          ...prevUser,
                          acDescription: data,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="float-end mt-3">
                  {anId ? (
                    <Fragment>
                      <Button
                        type="submit"
                        variant="contained"
                        color="success"
                        startIcon={<CheckIcon />}
                      >
                        Potvrda izmene
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        startIcon={<DeleteIcon />}
                        style={{ marginLeft: "10px" }}
                        onClick={toggleModal}
                      >
                        Obriši vest
                      </Button>
                    </Fragment>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<AddIcon />}
                    >
                      Kreiraj
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <CustomModal
          show={showModal}
          hideModal={modalCloseHandler}
          modalTitle={`Potvrda brisanja`}
          actionSubmitModal={() => submitActionModal(data.anId)}
        >
          Da li ste sigurni da želite da obrišete <b>{data.acTitle}</b> ?
        </CustomModal>
      )}
    </Fragment>
  );
};

export default BlogDetailAdmin;
