import React from "react";
import { Fragment } from "react";
import Breadcrumb from "../../components/Admin/Reusable/Breadcrumb";
import AggridTable from "../../components/Admin/Reusable/AggridTable";
import ButtonColumn from "../../components/Admin/Reusable/ButtonColumn";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchResourcesPagesList,
  fetchDeleteResources,
} from "../../actions/Admin/resources";
import { statusRender } from "../../utils/cellRenderTable";
import { getFileReadFromDocument } from "../../actions/Admin/document";

const ResourcesList = () => {
  const dispatch = useDispatch();

  const { dataPagesList } = useSelector((state) => state.resourcesReducer);

  useEffect(() => {
    dispatch(fetchResourcesPagesList());
  }, [dispatch]);

  //akcija brisanja iz liste
  const handleSubmitModal = (pageId) => {
    dispatch(fetchDeleteResources(pageId));
  };

  const handleFileRead = (document) => {
    dispatch(getFileReadFromDocument(document));
  };

  const documentRender = (params) => {
    return (
      <div
        className="link-from-table"
        onClick={() => handleFileRead(params.data.acDocument)}
      >
        {params.value}
      </div>
    );
  };

  const customCellRenderer = (params) => (
    <ButtonColumn
      editLink={`/adminManager/resources-edit/` + params.data.anId}
      deleteItemId={params.data.anId}
      deleteItemTitle={params.data.acName}
      value={params}
      submitActionModal={handleSubmitModal}
    />
  );

  //settings za tabelu
  const columnDefs = [
    { headerName: "ID", field: "anId", filter: true, width: 100 },
    {
      headerName: "Dokument",
      field: "acOriginalName",
      tooltipField: "acOriginalName",
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 250,
      cellRenderer: documentRender,
    },
    {
      headerName: "Naziv",
      field: "acName",
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 250,
    },
    {
      headerName: "Jezik",
      field: "acLanguageName",
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Status",
      field: "acStatusName",
      filter: "agTextColumnFilter",
      cellRenderer: statusRender,
    },
    {
      headerName: "Kreirao",
      field: "ins_acUsername",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Izmenio",
      field: "edit_acUsername",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Datum kreiranja",
      field: "adTimeIns",
      filter: "agDateColumnFilter",
    },
    {
      headerName: "Datum izmene",
      field: "adTimeEdit",
      filter: "agDateColumnFilter",
    },
    {
      headerName: "Panel",
      field: "custom",
      cellRenderer: customCellRenderer,
      pinned: "right",
      width: 120,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb title="Pregled klijenata" type="resources-lista" />
      <AggridTable rowData={dataPagesList} columnDefs={columnDefs} />
    </Fragment>
  );
};

export default ResourcesList;
