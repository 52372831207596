import React from "react";
import { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/stari/HelmetSeo";

const WhereBuy = () => {
  return (
    <Fragment>
      <HelmetSeo
        title="Gde kupiti | Prečišćivači"
        description="Prečišćivači"
        keywords="Prečišćivači"
      />

      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="partners-content-block">
              <h1 class="senExtraBold">
                Prodajni partneri brendova prečišćivača vazduha
              </h1>
              <div class="row">
                <div class="col-sm-6 text-left">
                  Obaveštavamo vas da je sajt prečišćivači.rs isključivo
                  informativnog karaktera. Na sajtu nije moguće naručivati niti
                  kupovati proizvode i ne funkcioniše u svrhu online prodaje.
                  <br />
                  <br />
                  Sajt prečišćivači.rs namenjen je prvenstveno edukaciji
                  posetilaca o značaju i funkciji prečišćivača vazduha i
                  brendovima koji ih proizvode – Xiaomi, Sencor, Sharp i Beko.
                </div>
                <div class="col-sm-6 text-left">
                  Ukoliko ste zainteresovani za kupovinu prečišćivača vazduha,
                  to možete učiniti kod prodajnih partnera, putem web shop-a ili
                  u maloprodajnim objektima.
                  <br />
                  <br />
                  Maloprodajni objekti zadržavaju pravo formiranja i promene
                  cena uređaja u skladu sa svojom politikom poslovanja.
                </div>
              </div>
              <div class="text-bottom-partners">
                Kompletnu listu prodajnih partnera pogledajte u nastavku.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2 col-6">
            <div class="partner-logo-item">
              <a
                href="https://www.shoppster.rs/search/pre%C4%8Di%C5%A1%C4%87iva%C4%8D?query=pre%C4%8Di%C5%A1%C4%87iva%C4%8D:relevance:brand:Sencor:brand:Sharp:brand:Xiaomi:brand:Beko"
                target="_blank"
              >
                <img
                  src="/images/1660728816.png"
                  alt="Shoppster"
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
          <div class="col-sm-2 col-6">
            <div class="partner-logo-item">
              <a
                href="https://gigatron.rs/mali-kucni-aparati/kucni-aparati/preciscivaci-vazduha?brand=!attr_brandBeko+!attr_brandXiaomi+!attr_brandSharp+!attr_brandSencor"
                target="_blank"
              >
                <img
                  src="/images/1660728056.png"
                  alt="Gigatron DOO"
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
          <div class="col-sm-2 col-6">
            <div class="partner-logo-item">
              <a
                href="https://www.tehnomedia.rs/kucni-aparati/aparat-za-tretiranje-vazduha/?filt%5B1422%5D%5B%5D=Preciscivac+vazduha&amp;filt%5B1794%5D%5B%5D=BEKO&amp;filt%5B1794%5D%5B%5D=SENCOR&amp;filt%5B1794%5D%5B%5D=SHARP&amp;filt%5B1794%5D%5B%5D=XIAOMI"
                target="_blank"
              >
                <img
                  src="/images/1660728790.png"
                  alt="Tehnomedia"
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
          <div class="col-sm-2 col-6">
            <div class="partner-logo-item">
              <a
                href="https://www.tehnomanija.rs/c/kucni-aparati/klime-grejanje-i-kvalitet-vazduha/preciscivaci-vazduha-i-oprema/filteri-i-oprema-za-preciscavanje-vazduha-1001030802"
                target="_blank"
              >
                <img
                  src="/images/1666812531.jpg"
                  alt="Tehnomanija"
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
          <div class="col-sm-2 col-6">
            <div class="partner-logo-item">
              <a
                href="https://bazzar.rs/c/preciscivaci-vazduha?see_all=true&amp;utf8=%E2%9C%93&amp;category_id=5176&amp;see_all=true&amp;q=&amp;sort=1&amp;brands%5B%5D=Sharp&amp;brands%5B%5D=Sencor&amp;brands%5B%5D=Xiaomi&amp;brands%5B%5D=BEKO"
                target="_blank"
              >
                <img
                  src="/images/1667898643.png"
                  alt="bazzar.rs"
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
          <div class="col-sm-2 col-6">
            <div class="partner-logo-item">
              <a
                href="https://www.ctshop.rs/preciscivaci-vazduha?brend%5B%5D=305&amp;brend%5B%5D=379&amp;brend%5B%5D=42&amp;brend%5B%5D=556&amp;cena="
                target="_blank"
              >
                <img
                  src="/images/1667898803.png"
                  alt="CT Shop"
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
          <div class="col-sm-2 col-6">
            <div class="partner-logo-item">
              <a
                href="https://www.drtechno.rs/mali-kucni-aparati/kucni-aparati/aparati-za-tretiranje-vazduha.html?manufacturer=951%2C1958%2C1579"
                target="_blank"
              >
                <img
                  src="/images/1667899019.png"
                  alt="Dr Techno"
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
          <div class="col-sm-2 col-6">
            <div class="partner-logo-item">
              <a
                href="https://www.ekupi.rs/rs/Ku%C4%87ni-aparati/Grejanje-i-hla%C4%91enje/Regulatori-vla%C5%BEnosti-vazduha/c/11922?q=%3Arelevance%3Abrand%3AXiaomi%3Abrand%3ASharp%3Abrand%3ASencor&amp;text=#"
                target="_blank"
              >
                <img
                  src="/images/1666812501.jpg"
                  alt="eKupi.rs"
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
          <div class="col-sm-2 col-6">
            <div class="partner-logo-item">
              <a
                href="https://www.emmezeta.rs/bela-tehnika/lepota-i-zdravlje/preciscivaci-vazduha.html?brand=SHARP&amp;brand=SENCOR"
                target="_blank"
              >
                <img
                  src="/images/1660728874.png"
                  alt="Emmezeta"
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
          <div class="col-sm-2 col-6">
            <div class="partner-logo-item">
              <a
                href="https://www.bcgroup-online.com/preciscivac-vazduha#prod[]=25&amp;prod[]=203&amp;prod[]=205&amp;prod[]=259&amp;price=&amp;cid=334"
                target="_blank"
              >
                <img
                  src="/images/1666812509.jpg"
                  alt="BC Group"
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
          <div class="col-sm-2 col-6">
            <div class="partner-logo-item">
              <a
                href="https://www.list.rs/katalog/175/Ostali-kucni-aparati/?value%5B%5D=747_36881"
                target="_blank"
              >
                <img
                  src="/images/1666812403.jpg"
                  alt="List"
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WhereBuy;
