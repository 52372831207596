import "./App.css";

import { locationPath } from "./constants/appRoutes";
import { Route, Routes } from "react-router-dom";
import SecuredRoute from "./utils/security/SecuredRoute";
import AdminRoute from "./utils/security/AdminRoute";

import AuthContext from "./context/auth_context";

import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import store from "./reducers";
import { securityActions } from "./reducers/security";

//pages site
import Home from "./pages/Site/Home";
import AboutUs from "./pages/Site/AboutUs";
import Contact from "./pages/Site/Contact";
import Blog from "./pages/Site/Blog";
import BlogDetail from "./pages/Site/BlogDetail";
import WhereBuy from "./pages/Site/WhereBuy";
import Brands from "./pages/Site/Brands";
import BrandsDetail from "./pages/Site/BrandsDetail";
import Privacy from "./pages/Site/Privacy";
import PrivacyCookie from "./pages/Site/PrivacyCookie";
import Registration from "./pages/Site/Registration";
import Karcher from "./pages/Site/Karcher";
import Products from "./pages/Site/Products";
import ProductDetail from "./pages/Site/ProductDetail";

//pages admin
import SeoPagesForm from "./pages/Admin/SeoPagesForm";
import SeoPagesList from "./pages/Admin/SeoPagesList";
import Dashboard from "./pages/Admin/Dashboard";
import Login from "./pages/Admin/Login";

//css
import "./assets/Site/header.css";
import "./assets/Site/home.css";
import "./assets/Site/index.css";
import "./assets/Site/landing.css";
import "./assets/Site/responsive.css";

import "bootstrap/dist/css/bootstrap.min.css";
import ErrorPage from "./pages/Site/ErrorPage";
import Qa from "./pages/Admin/Qa";
import QaList from "./pages/Admin/QaList";
import Document from "./pages/Admin/Document";
import DocumentList from "./pages/Admin/DocumentList";
import Client from "./pages/Admin/Client";
import ClientList from "./pages/Admin/ClientList";
import BlogList from "./pages/Admin/BlogList";
import BlogAdmin from "./pages/Admin/Blog";
import BlogDetailAdmin from "./pages/Admin/BlogDetailAdmin";
import CaseStudy from "./pages/Admin/CaseStudy";
import CaseStudyList from "./pages/Admin/CaseStudyList";
import ContactFormList from "./pages/Admin/ContactFormList";
import NotFound from "./components/Admin/NotFound";
import RoleList from "./pages/Admin/RoleList";
import Role from "./pages/Admin/Role";
import User from "./pages/Admin/User";
import UserList from "./pages/Admin/UserList";
import Testimonials from "./pages/Admin/Testimonials";
import TestimonialsList from "./pages/Admin/TestimonialsList";
import ResourcesAdmin from "./pages/Admin/Resources";
import ResourcesList from "./pages/Admin/ResourcesList";
import Team from "./pages/Admin/Team";
import TeamList from "./pages/Admin/TeamList";
import Product from "./pages/Admin/Product";
import ProductList from "./pages/Admin/ProductList";
import { Helmet } from "react-helmet-async";

const phpToken = localStorage.getItem("phpToken");
const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

if (phpToken) {
  let items = {
    validToken: phpToken,
    loggedUser,
  };
  store.dispatch(securityActions.setCurrentUser(items));
}

function App() {
  const loggedUserReducer = useSelector((state) => state.loggedUserReducer);

  const checkRoute = (component, action) => {
    const allowedActions = loggedUserReducer?.loggedUser?.loggedUser?.roles[0];
    if (allowedActions)
      if (!allowedActions.includes(action)) {
        return NotFound;
      } else {
        return component;
      }
  };

  return (
    <AuthContext.Provider
      value={{
        ...loggedUserReducer,
      }}
    >
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/ns.html?id=GTM-MKB84B58"
        ></script>
      </Helmet>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <SecuredRoute>
              <Home />
            </SecuredRoute>
          }
        />
        <Route
          path="/kontakt"
          element={
            <SecuredRoute>
              <Contact />
            </SecuredRoute>
          }
        />
        <Route
          path="/brendovi"
          element={
            <SecuredRoute>
              <Brands />
            </SecuredRoute>
          }
        />
        <Route
          path="/brend-detaljno/:id"
          element={
            <SecuredRoute>
              <BrandsDetail />
            </SecuredRoute>
          }
        />
        <Route
          path="/gde-kupiti"
          element={
            <SecuredRoute>
              <WhereBuy />
            </SecuredRoute>
          }
        />
        <Route
          path="/registracija"
          element={
            <SecuredRoute>
              <Registration />
            </SecuredRoute>
          }
        />

        <Route
          path="/blog"
          element={
            <SecuredRoute>
              <Blog />
            </SecuredRoute>
          }
        />
        <Route
          path="/blog/:url/:id"
          element={
            <SecuredRoute>
              <BlogDetail />
            </SecuredRoute>
          }
        />
        <Route
          path="/politika-upotrebe-kolacica"
          element={
            <SecuredRoute>
              <PrivacyCookie />
            </SecuredRoute>
          }
        />
        <Route
          path="/politika-privatnosti"
          element={
            <SecuredRoute>
              <Privacy />
            </SecuredRoute>
          }
        />
        <Route
          path="/proizvodi"
          element={
            <SecuredRoute>
              <Products />
            </SecuredRoute>
          }
        />
        <Route
          path="/proizvod/:name/:id"
          element={
            <SecuredRoute>
              <ProductDetail />
            </SecuredRoute>
          }
        />
        <Route
          path="/karcher-preciscivaci"
          element={
            <SecuredRoute>
              <Karcher />
            </SecuredRoute>
          }
        />
        <Route
          path="*"
          element={
            <SecuredRoute>
              <ErrorPage />
            </SecuredRoute>
          }
        />

        {/* ADMIN RUTE */}
        <Route exact path="/adminManager/login" element={<Login />}></Route>
        <Route
          exact
          path={locationPath.DASHBOARD}
          element={
            <AdminRoute component={checkRoute(Dashboard, "MENU_DASHBOARD")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.QA_ADD}
          element={<AdminRoute component={checkRoute(Qa, "MENU_QA")} />}
        ></Route>
        <Route
          exact
          path={locationPath.QA_EDIT}
          element={<AdminRoute component={checkRoute(Qa, "MENU_QA")} />}
        ></Route>
        <Route
          exact
          path={locationPath.QA_LIST}
          element={<AdminRoute component={checkRoute(QaList, "MENU_QA")} />}
        ></Route>
        <Route
          exact
          path={locationPath.CLIENT_ADD}
          element={
            <AdminRoute component={checkRoute(Client, "MENU_CLIENTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CLIENT_EDIT}
          element={
            <AdminRoute component={checkRoute(Client, "MENU_CLIENTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CLIENT_LIST}
          element={
            <AdminRoute component={checkRoute(ClientList, "MENU_CLIENTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.TESTIMONIALS_ADD}
          element={
            <AdminRoute
              component={checkRoute(Testimonials, "MENU_TESTIMONIALS")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.TESTIMONIALS_EDIT}
          element={
            <AdminRoute
              component={checkRoute(Testimonials, "MENU_TESTIMONIALS")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.TESTIMONIALS_LIST}
          element={
            <AdminRoute
              component={checkRoute(TestimonialsList, "MENU_TESTIMONIALS")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.USER_ADD}
          element={<AdminRoute component={checkRoute(User, "MENU_USERS")} />}
        ></Route>
        <Route
          exact
          path={locationPath.USER_EDIT}
          element={<AdminRoute component={checkRoute(User, "MENU_USERS")} />}
        ></Route>
        <Route
          exact
          path={locationPath.USER_LIST}
          element={
            <AdminRoute component={checkRoute(UserList, "MENU_USERS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.ROLE_ADD}
          element={<AdminRoute component={checkRoute(Role, "MENU_ROLES")} />}
        ></Route>
        <Route
          exact
          path={locationPath.ROLE_EDIT}
          element={<AdminRoute component={checkRoute(Role, "MENU_ROLES")} />}
        ></Route>
        <Route
          exact
          path={locationPath.ROLE_LIST}
          element={
            <AdminRoute component={checkRoute(RoleList, "MENU_ROLES")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.BLOG_ADD}
          element={
            <AdminRoute component={checkRoute(BlogAdmin, "MENU_BLOG")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.BLOG_EDIT}
          element={
            <AdminRoute component={checkRoute(BlogAdmin, "MENU_BLOG")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.BLOG_TRANSLATION_ADD}
          element={
            <AdminRoute component={checkRoute(BlogDetailAdmin, "MENU_BLOG")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.BLOG_TRANSLATION_EDIT}
          element={
            <AdminRoute component={checkRoute(BlogDetailAdmin, "MENU_BLOG")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.BLOG_LIST}
          element={<AdminRoute component={checkRoute(BlogList, "MENU_BLOG")} />}
        ></Route>
        <Route
          exact
          path={locationPath.DOCUMENT_ADD}
          element={
            <AdminRoute component={checkRoute(Document, "MENU_DOCUMENTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.DOCUMENT_EDIT}
          element={
            <AdminRoute component={checkRoute(Document, "MENU_DOCUMENTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.DOCUMENT_LIST}
          element={
            <AdminRoute
              component={checkRoute(DocumentList, "MENU_DOCUMENTS")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CASE_STUDY_ADD}
          element={
            <AdminRoute component={checkRoute(CaseStudy, "MENU_CASE_STUDY")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CASE_STUDY_EDIT}
          element={
            <AdminRoute component={checkRoute(CaseStudy, "MENU_CASE_STUDY")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CASE_STUDY_LIST}
          element={
            <AdminRoute
              component={checkRoute(CaseStudyList, "MENU_CASE_STUDY")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.CONTACT_LIST}
          element={
            <AdminRoute
              component={checkRoute(ContactFormList, "MENU_CONTACT_FORM")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.SEO_LIST}
          element={
            <AdminRoute component={checkRoute(SeoPagesList, "MENU_SEO")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.SEO_FORM}
          element={
            <AdminRoute component={checkRoute(SeoPagesForm, "MENU_SEO")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.TEAM_ADD}
          element={<AdminRoute component={checkRoute(Team, "MENU_TEAM")} />}
        ></Route>
        <Route
          exact
          path={locationPath.TEAM_EDIT}
          element={<AdminRoute component={checkRoute(Team, "MENU_TEAM")} />}
        ></Route>
        <Route
          exact
          path={locationPath.TEAM_LIST}
          element={<AdminRoute component={checkRoute(TeamList, "MENU_TEAM")} />}
        ></Route>
        <Route
          exact
          path={locationPath.RESOURCES_ADD}
          element={
            <AdminRoute
              component={checkRoute(ResourcesAdmin, "MENU_RESOURCES")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.RESOURCES_EDIT}
          element={
            <AdminRoute
              component={checkRoute(ResourcesAdmin, "MENU_RESOURCES")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.RESOURCES_LIST}
          element={
            <AdminRoute
              component={checkRoute(ResourcesList, "MENU_RESOURCES")}
            />
          }
        ></Route>
        <Route
          exact
          path={locationPath.PRODUCT_ADD}
          element={
            <AdminRoute component={checkRoute(Product, "MENU_PRODUCTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.PRODUCT_EDUT}
          element={
            <AdminRoute component={checkRoute(Product, "MENU_PRODUCTS")} />
          }
        ></Route>
        <Route
          exact
          path={locationPath.PRODUCT_LIST}
          element={
            <AdminRoute component={checkRoute(ProductList, "MENU_PRODUCTS")} />
          }
        ></Route>
        {/* Dodajte noscript dijelove */}
      </Routes>
      <ToastContainer />
    </AuthContext.Provider>
  );
}

export default App;
