import { userLogin, userLogout, getMenuItems } from "../../constants/apiRoutes";
import { securityActions } from "../../reducers/security";
import apiService from "../../utils/configAxios";

import jwtDecode from "jwt-decode";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const login = (loginRequest, navigate) => {
  return (dispatch) => {
    return apiService
      .post(userLogin(), loginRequest)
      .then((response) => {
        let token = response.data.token;
        const userInfo = jwtDecode(token);

        let items = {
          validToken: response.data.token,
          loggedUser: userInfo,
        };
        localStorage.setItem("phpToken", response.data.token);
        localStorage.setItem("loggedUser", JSON.stringify(userInfo));
        dispatch(securityActions.setCurrentUser(items));
        navigate("/adminManager/dashboard");
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchSidebarMenu = () => {
  return (dispatch) => {
    return apiService
      .get(getMenuItems())
      .then((response) => {
        dispatch(securityActions.setMenuItemsList(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const logout = (navigate) => {
  return (dispatch) => {
    return apiService
      .get(userLogout())
      .then((response) => {
        dispatch(securityActions.setCurrentUser({}));
        localStorage.clear();
        navigate("/adminManager/login");
      })
      .catch((err) => {
        // console.log(err, "err");
        toast.error(err.response.data.messages.error, { theme: "colored" });
      });
  };
};

export const clearHeadersAndTokens = () => {
  localStorage.clear();
};
