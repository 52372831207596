import { Chip } from "@mui/material";

export const statusRender = (params) => {
  switch (params.data.acStatusName) {
    case "Neaktivan":
      return <Chip label="Neaktivan" color="error" variant="outlined" />;
    case "Aktivan":
      return <Chip label="Aktivan" color="success" variant="outlined" />;
    default:
      break;
  }
};

export const primaryRender = (params) => {
  switch (params.data.isPrimary) {
    case false:
      return <Chip label="Ne" color="error" variant="outlined" />;
    case true:
      return <Chip label="Da" color="success" variant="outlined" />;
    default:
      break;
  }
};
