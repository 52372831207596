import {
  addNewDocument,
  addNewDocumentCategory,
  getDocumentPagesList,
  getReadFilePath,
  editDocument,
  getAllDocumentCategories,
  editDocumentCategory,
  documentItemDelete,
  documentCategoryItemDelete,
} from "../../constants/apiRoutes";
import { documentActions } from "../../reducers/Admin/documentActions";
import apiService from "../../utils/configAxios";
import { toast } from "react-toastify";

export const createNewDocument = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(addNewDocument(), dataValues)
      .then((response) => {
        navigate("/adminManager/document-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const createNewDocumentCategory = (
  dataValues,
  handleFunction,
  navigate
) => {
  return (dispatch) => {
    return apiService
      .post(addNewDocumentCategory(), dataValues)
      .then((response) => {
        handleFunction();
        toast.success(response.data.message, { theme: "colored" });
        return apiService
          .get(getAllDocumentCategories())
          .then((response) => {
            dispatch(
              documentActions.fetchAllDocumentCategoriesList(response.data)
            );
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const deleteItem = (pageId) => {
  return (dispatch) => {
    dispatch(documentActions.actionStart());
    return apiService
      .delete(documentItemDelete(pageId))
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        return apiService
          .get(getDocumentPagesList(0))
          .then((response) => {
            dispatch(documentActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const deleteDocumentCategoryItem = (pageId, handlerDeleteModal) => {
  return (dispatch) => {
    dispatch(documentActions.actionStart());
    return apiService
      .delete(documentCategoryItemDelete(pageId))
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        handlerDeleteModal();
        return apiService
          .get(getAllDocumentCategories())
          .then((response) => {
            dispatch(
              documentActions.fetchAllDocumentCategoriesList(response.data)
            );
          })
          .catch((err) => {
            handlerDeleteModal();
            let errorMessage = err.response ? err.response.data.message : err;
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        handlerDeleteModal();
        let errorMessage = err.response ? err.response.data.message : err;
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const editDocumentFunction = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(editDocument(), dataValues)
      .then((response) => {
        navigate("/adminManager/document-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const editDocumentCategoryFunction = (
  dataValues,
  handleFunction,
  navigate,
  anId
) => {
  return (dispatch) => {
    dataValues["anId"] = anId;
    return apiService
      .post(editDocumentCategory(), dataValues)
      .then((response) => {
        handleFunction();
        toast.success(response.data.message, { theme: "colored" });

        return apiService
          .get(getAllDocumentCategories())
          .then((response) => {
            dispatch(
              documentActions.fetchAllDocumentCategoriesList(response.data)
            );
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchDocumentPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(documentActions.actionStart());
    return apiService
      .get(getDocumentPagesList(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(documentActions.fetchAllPagesList(response.data));
        else dispatch(documentActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchAllDocumentCategories = () => {
  return (dispatch) => {
    dispatch(documentActions.actionStart());
    return apiService
      .get(getAllDocumentCategories())
      .then((response) => {
        dispatch(documentActions.fetchAllDocumentCategoriesList(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const getFileReadFromDocument = (document) => {
  return (dispatch) => {
    dispatch(documentActions.actionStart());
    return apiService
      .getFromFile(getReadFilePath(document))
      .then((response) => {
        // Pravimo Blob objekat od podataka koje smo dobili
        const blob = new Blob([response.data], { type: "application/pdf" });

        // Kreiramo URL za Blob
        const url = URL.createObjectURL(blob);

        // Otvaranje URL-a u novom tabu
        window.open(url, "_blank");
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const downloadFile = (file, name) => {
  if (!file) return;
  const fileForDownload = !(file instanceof Blob) ? new Blob([file]) : file;
  const downloadLink = document.createElement("a");
  const objectURL = URL.createObjectURL(fileForDownload);
  downloadLink.href = objectURL;
  downloadLink.download = name || fileForDownload?.name || "file";
  downloadLink.click();
  URL.revokeObjectURL(objectURL);
};
