import React, { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/stari/HelmetSeo";
import Contact from "../../components/Site/Contact";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchProductsListItems,
  fetchSpecificationListItems,
  fetchPartnersListItems,
} from "../../actions/Site/global";
import { useState } from "react";
import i18next from "i18next";
import BlogList from "./BlogList";
import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../utils";
import BlogItem from "./BlogItem";
import { useModalToggle } from "../../hooks/useModalToggle";
import CustomModal from "../../components/Common/Modal/CustomModal";

const ProductDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState("");

  const [showModal, toggleModal] = useModalToggle(false);

  const {
    dataPagesListProducts,
    dataPagesListPartners,
    fetchItemsListSpecification,
  } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchProductsListItems(id));
    dispatch(fetchSpecificationListItems(id));
  }, [dispatch]);

  const modalCloseHandler = () => {
    toggleModal(false);
  };

  const toggleModalFunction = (id) => {
    toggleModal(true);
    dispatch(fetchPartnersListItems(id));
  };

  console.log(fetchItemsListSpecification, "fetchItemsListSpecification");

  return (
    <Fragment>
      {dataPagesListProducts[0] && (
        <>
          <HelmetSeo
            title={`${dataPagesListProducts[0].acSeoTitle} | Prečišćivači`}
            description={dataPagesListProducts[0].acSeoDescription}
            keywords={dataPagesListProducts[0].acSeoKeywords}
          />

          <div class="background-gray product-detail-container">
            <div class="container">
              <div class="row flex-direction-column-mobile">
                <div class="product-detail-gallery">
                  <div class="product-detail-image-list">
                    <div class="product-detail-image-list-item">
                      <img
                        src={`https://resource.ewe.rs/products/${dataPagesListProducts[0].acIdent}_v.jpg`}
                        alt=""
                        class="img-fluid product-detail-image-list-item-image"
                        onClick={() =>
                          setSelectedImage(
                            `https://resource.ewe.rs/products/${dataPagesListProducts[0].acIdent}_v.jpg`
                          )
                        }
                      />
                    </div>
                    <div class="product-detail-image-list-item">
                      <img
                        src={`https://resource.ewe.rs/products/${dataPagesListProducts[0].acIdent}_1.jpg`}
                        onClick={() =>
                          setSelectedImage(
                            `https://resource.ewe.rs/products/${dataPagesListProducts[0].acIdent}_1.jpg`
                          )
                        }
                        alt=""
                        class="img-fluid product-detail-image-list-item-image"
                      />
                    </div>
                    <div class="product-detail-image-list-item">
                      <img
                        src={`https://resource.ewe.rs/products/${dataPagesListProducts[0].acIdent}_2.jpg`}
                        onClick={() =>
                          setSelectedImage(
                            `https://resource.ewe.rs/products/${dataPagesListProducts[0].acIdent}_2.jpg`
                          )
                        }
                        alt=""
                        class="img-fluid product-detail-image-list-item-image"
                      />
                    </div>
                    <div class="product-detail-image-list-item">
                      <img
                        src={`https://resource.ewe.rs/products/${dataPagesListProducts[0].acIdent}_3.jpg`}
                        onClick={() =>
                          setSelectedImage(
                            `https://resource.ewe.rs/products/${dataPagesListProducts[0].acIdent}_3.jpg`
                          )
                        }
                        alt=""
                        class="img-fluid product-detail-image-list-item-image"
                      />
                    </div>
                    <div class="product-detail-image-list-item">
                      <img
                        src={`https://resource.ewe.rs/products/${dataPagesListProducts[0].acIdent}_4.jpg`}
                        onClick={() =>
                          setSelectedImage(
                            `https://resource.ewe.rs/products/${dataPagesListProducts[0].acIdent}_4.jpg`
                          )
                        }
                        alt=""
                        class="img-fluid product-detail-image-list-item-image"
                      />
                    </div>
                    <div class="product-detail-image-list-item">
                      <img
                        src={`https://resource.ewe.rs/products/${dataPagesListProducts[0].acIdent}_5.jpg`}
                        onClick={() =>
                          setSelectedImage(
                            `https://resource.ewe.rs/products/${dataPagesListProducts[0].acIdent}_5.jpg`
                          )
                        }
                        alt=""
                        class="img-fluid product-detail-image-list-item-image"
                      />
                    </div>
                  </div>
                  <div class="product-detail-image-big">
                    <div class="badge-content-list"></div>
                    <img
                      src={
                        selectedImage
                          ? selectedImage
                          : baseUrl +
                            `images${dataPagesListProducts[0].acImageProduct}`
                      }
                      alt=""
                      class="img-fluid product-detail-image-big-item"
                    />
                  </div>
                </div>
                <div class="product-detail-holder">
                  <div class="product-detail-content">
                    <div class="product-detail-title-content-top">
                      <div class="product-detail-title-content">
                        <div class="product-detail-cateogry green-color"></div>
                        <div class="product-detail-title karlaExtraBold">
                          {dataPagesListProducts[0].acBrand} -
                          {dataPagesListProducts[0].acName}
                        </div>
                        <div class="product-detail-model green-color">
                          {dataPagesListProducts[0].acIdent}
                        </div>
                        <div class="product-detail-type">
                          {dataPagesListProducts[0].acSquare}m²
                        </div>
                        <div class="product-detail-type">
                          {dataPagesListProducts[0].acVolume}m³/h
                        </div>
                        <div class="product-detail-desc"></div>
                        <div class="product-detail-dimension">
                          Dimenzije: {dataPagesListProducts[0].acDimension}
                        </div>

                        <div class="product-detail-desc-special mt-4"></div>
                      </div>
                      <div class="product-detail-logo-content">
                        <img
                          class="img-fluid"
                          src={`http://resource.ewe.rs/manufacturers/${dataPagesListProducts[0].acBrand}.png`}
                        />
                      </div>
                    </div>
                    <div class="product-detail-title-content-bottom">
                      <button
                        type="button"
                        class="contact-button product-button productButtonShop"
                        data-id="57"
                        onClick={() =>
                          toggleModalFunction(dataPagesListProducts[0].anId)
                        }
                      >
                        Gde kupiti?
                      </button>
                      <Link to="/registracija">
                        <button class="button-product-registration">
                          Registrujte proizvod &gt;
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="container-fluid brands-detail-page">
              <div class="row brand-gallery">
                <div class="col-sm-4">
                  <img
                    class="img-fluid w-100"
                    src="/media/gallery/1663147608-sencor-sha-9400-wh-b-jpg.jpg"
                  />
                </div>
                <div class="col-sm-4">
                  <img
                    class="img-fluid w-100"
                    src="/media/gallery/1663147619-sencor-sha-9400-wh-jpg.jpg"
                  />
                </div>
              </div>
            </div> */}
            <div class="container">
              <div class="row">
                <div class="col-sm-12">
                  <div
                    class="blog-title-homepage title-product-detail-tabs"
                    style={{ marginTop: "80px" }}
                  >
                    <div
                      class="product-detail-tab-item product-detail-tab-item-first active"
                      data-tab="1"
                    >
                      Specifikacija
                    </div>
                  </div>
                  <div class="specification-table tabContent tabContent1">
                    <table class="table specification-table-detail">
                      <tbody>
                        {fetchItemsListSpecification?.map((item, key) => (
                          <>
                            <tr>
                              <td colspan="2" class="green-color">
                                {item.acName}
                              </td>
                            </tr>
                            {item.sublist?.map((item2, key2) => (
                              <tr>
                                <td class="border-table-right">
                                  {item2.acName}
                                </td>
                                <td>{item2.acValue}</td>
                              </tr>
                            ))}
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div class="iframeDescProduct tabContent tabContent2">
                    <iframe
                      id="iframe"
                      src="https://resource.ewe.rs/media/pages//index.php"
                      width="100%"
                      height="100%"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showModal && (
        <CustomModal
          size={"lg"}
          show={showModal}
          hideModal={modalCloseHandler}
          classValue={`modal-1`}
        >
          <div class="row">
            <div class="col-sm-12 text-center modal-title-text">
              <h4>Prodajni partneri</h4>
            </div>
            <div class="content-container-list-partners">
              <div class="row justify-content-center">
                {dataPagesListPartners?.map((item, key) => (
                  <div class="col-sm-3 col-6 mobile-margin-bottom-partner">
                    <a href={item.acLink} target="_blank">
                      <img
                        src={`/images/${item.acImage}`}
                        class="img-fluid"
                        alt={item.acName}
                      />
                    </a>
                  </div>
                ))}
              </div>
              <Link to="/gde-kupiti" class="text-center">
                <button class="button-product-registration show-all-partners green-color robotoRegular">
                  Pogledajte sve partnere &gt;
                </button>
              </Link>
            </div>
          </div>
        </CustomModal>
      )}
    </Fragment>
  );
};

export default ProductDetail;
