import React from "react";

const Contact = () => {
  return (
    <section className="section section-contact-home">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="contact-content">
              <div className="contact-home-form">
                <h2 className="contact-home-form-title">Kontaktirajte nas</h2>
                <form id="contact-form" name="contact-form">
                  <div className="contact-home-form-box">
                    <div className="row">
                      <div className="col-sm-6">
                        <input
                          type="text"
                          name="firstName"
                          className="custom-input-home-contact"
                          required=""
                          placeholder="Ime*"
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          name="lastName"
                          className="custom-input-home-contact"
                          required=""
                          placeholder="Prezime*"
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          name="email"
                          className="custom-input-home-contact input-top-margin-contact"
                          placeholder="Elektronska pošta *"
                          required=""
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          name="subject"
                          className="custom-input-home-contact input-top-margin-contact"
                          placeholder="Tema *"
                          required=""
                        />
                      </div>
                      <div className="col-sm-12">
                        <textarea
                          name="message"
                          className="custom-input-home-contact input-top-margin-contact"
                          placeholder="Poruka *"
                          rows="1"
                          required=""
                        ></textarea>
                      </div>

                      <input
                        type="hidden"
                        id="g-recaptcha-response"
                        name="g-recaptcha-response"
                        value="03AFcWeA7XTgipAENpeiY_1OGbWgQ7BYc7QLaLbVfoTgTKu365P3beKHU6ZaIUmuDlBl4Z3sdyGCkeq8VDvZyoR1fE37c74PhgFYu6aapBazVzc5mQ-S6xBNxDDN0Hz4zTL6EH7OYGii7qGhWriv2QRwoLZxPne4m9n3Y7yeYyAKrq0ab1Bd3AP_azKtuiXmrWxYj5qj7s3dSD6V7RUj1Iv5XcxG8wXLaID7s2_oG6ygmS2Gj4WPTnq9bkhIkPdoF1z5RmSEWiazP0V0TL5erSK1094Bo5azEqXEeitiO96pbvRahWlJhXwZBPL6kw-c6YQ6GoOvczndz9dqCFeW1x3ammUyoXqfP6oCOS8Q3muvYck7FcmVCRR8tXr5O1F6Khhy6ajMMHMWrkheyM66QyBKlUsLyrYwn83ApC4cm7W6vnjjlQfGNLu6SxyyKv3SJcP0dh9JxvHo6WTvfrPpVhL3Cw0vavc33Z7snjJkV5rsBiuElGyBgbH6CCmDCYGy93nfNGQfcgYAYGQIXsVzePsXcQIcsS9BWArg8nWkCdftMbGiL_cFXaNQGhLr839CLvmdvGyQZSrPO4luh-Cz6I01J781Pvx0nmNd4bEyCh_1vU0EydntOlwqQh8N9WfBihu1pwb05-O0AolyO_sOXypBrgvVMjBAPsmn0816oLROiRBprad3um962WUbLVR0LVoT2c8r1M8ZZ3L6gfnJXrIWXpSlSNL_KWWxUquG31wpJXqQrKLd_gtcdpB6NCFOPdLSr5NvwuTN5k1hMWist6WdWw2qKHf9y3-M2MXI808B7R-j_NXmwJIM5L9EcELTWzqf5OlDFUEn0LbbtzbsGi18R3bdAQzMNEcYCy4FCqPXlegg-LSp1dOQxEf75HGyLFSBjmWJSDQhhMicsfONEIs4P0bO89jkJJsMUz-dDQ1n4Dxa4OmaBK89Pqmo-tt9q6G3bCSSDFCjAgeFS2_Y2MDK5CNPz4M6X98Gfk1rrYmLMU9pLIkeEgmHheBxK5qoRJbaOt__soqrH5u6EdMx4dU78QfP6zZNELV8zUJwJL2qp0a3c-4-njrnbupYjMDUDRykjQ8LSVHAfKaWmFBnFDcUp2KflB2i25-F624uxnWiCEtHbdlKNR0RjYyMr_pJMd5wRhbVGF33rsEAwy1hGzWgWvIunhXBpszuczZBJ9RCdO_tOgbMq4FTsxnaBb-ifBPtkp05v_YM1f4fSgv3iuolK3eINIly8hVye2mBG_3_lVbtZMFNOYZUAaFNvLyCymShQaCJi9x45OMTtRozRyzxZlJCW5S5I5j2gPsAN89Gc3xMy9Wx6G8-InIW5YJEPrn8SZmSG1pDPc2NXN3tQsSoJMZmDIsXfDlUSESn9ddis1F4zLJLHrJFFesJ3_uo4ExEMSgPzOG-W_yYIr1f4QzmUK5BRhFGTNVShDcsbBUlcB9C7WX7O4qxGW6CSGQk57O4yv-Uiy4-Kg9f-oQJ5G5VnJu1DihdKODo9mRdWLB0YkDrTytatCicqal0l1NDiwIWQmrh3eO8ju0AjSMxPoRa7nAKE7eFGihjFU_noTdqhcFOWerwPe7hKybyG-zN9KqXNuEG8_prNxzMlTGyU5EnB6rIJabyk2z0C7S27ICOqUuWl14gxoDQQNDs7FIm2n933-6jNgXnjLHH9IKhliAR0HFRPZ5Mt8gGYYNvp0yj4vcE5L6KGZlkomPm5sW2qE_w21lhZ92vqX"
                      />

                      <div className="contact-submit-button">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckChecked"
                            name="flexCheckChecked"
                          />
                          <label
                            className="form-check-label from-label-policy"
                            htmlFor="flexCheckChecked"
                          >
                            Pročitao sam i prihvatam Odredbe i uslove i Politiku
                            privatnosti.
                          </label>
                        </div>
                        <button
                          className="btn-custom-contanct-home button-whit-full-dark-hover"
                          id="contact-button-submit-form"
                        >
                          Pošalji
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="contact-home-text">
                <div className="contact-home-text-title">
                  Sviđa Vam se sve što vidite, ali morate to i da demonstrirate
                  svom timu? Kontaktirajte nas kako bi Vam prosledili PDF
                  prezentaciju koju možete da podelite sa Vašim kolegama.
                </div>
                <div className="contact-home-text-address">
                  Hotel Jugoslavija,
                </div>
                <div className="contact-home-text-address">
                  Bulevar Nikole Tesle 3,
                </div>
                <div className="contact-home-text-address">Beograd, Srbija</div>
                <div className="contact-home-social-network">
                  <div className="social-home-network-item">
                    <a
                      href="https://www.instagram.com/relog.media/?hl=en"
                      target="_blank"
                    >
                      <img
                        src="/images/insta.png"
                        alt="Relog Media"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                  <div className="social-home-network-item">
                    <a
                      href="https://www.facebook.com/RelogMedia/"
                      target="_blank"
                    >
                      <img
                        src="/images/fb.png"
                        alt="Relog Media"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                  <div className="social-home-network-item">
                    <a
                      href="https://www.youtube.com/@TVARENAESPORT"
                      target="_blank"
                    >
                      <img
                        src="/images/yt.png"
                        alt="Relog Media"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
