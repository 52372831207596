import React, { useEffect } from "react";
import { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/stari/HelmetSeo";
import HeroNewsSlider from "../../components/Site/HeroNewsSlider";

import Slider from "react-slick";
import { useState } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBlogListItems,
  fetchInfoListItems,
} from "../../actions/Site/global";
import BlogItem from "./BlogItem";
import { Link } from "react-router-dom";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src="/images/desno.jpg" className="img-fluid" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src="/images/levo.jpg" className="img-fluid" />
    </div>
  );
}

function Home() {
  const dispatch = useDispatch();
  const { dataPagesListBlogAll, fetchItemsListInfo } = useSelector(
    (state) => state.globalReducer
  );
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    setCurrentTime(new Date());
  }, []);

  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // Postavljamo da bude 24-satni format
  };

  const formattedTime = currentTime
    .toLocaleString("en-US", options)
    .replace(/\bat\b/, ",");

  useEffect(() => {
    dispatch(fetchBlogListItems());
    dispatch(fetchInfoListItems());
  }, [dispatch]);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    swipeToSlide: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const infoData = fetchItemsListInfo.data;

  let backgroundImage;
  let vazduh;
  let classColor;
  let scaleLine;
  let colorScale;
  // console.log(infoData, "infoData");
  if (infoData) {
    if (infoData.aqi <= 50) {
      backgroundImage = "url(/images/kvalitet-vazduha-dobar.png)";
      vazduh = "dobar";
      classColor = "green";
      scaleLine = "scale-line-green";
      colorScale = "green-scale";
    } else if (infoData.aqi > 50 && infoData.aqi <= 100) {
      backgroundImage = "url(/images/kvalitet-vazduha-prihvatljiv.png)";
      vazduh = "prihvatljiv";
      classColor = "yellow";
      scaleLine = "scale-line-yellow";
      colorScale = "yellow-scale";
    } else if (infoData.aqi > 100 && infoData.aqi <= 150) {
      backgroundImage = "url(/images/kvalitet-vazduha-los.png)";
      vazduh = "loš";
      classColor = "orange";
      scaleLine = "scale-line-orange";
      colorScale = "orange-scale";
    } else if (infoData.aqi > 150 && infoData.aqi <= 200) {
      backgroundImage = "url(/images/kvalitet-vazduha-veoma-los.png)";
      vazduh = "veoma loš";
      classColor = "red";
      scaleLine = "scale-line-red";
      colorScale = "red-scale";
    } else {
      backgroundImage = "url(/images/kvalitet-vazduha-opasan.png)";
      vazduh = "opasan";
      classColor = "purple";
      scaleLine = "scale-line-purple";
      colorScale = "purple-scale";
    }
  }

  return (
    <Fragment>
      <HelmetSeo
        title="Početna strana | Prečišćivači"
        description="Prečišćivači"
        keywords="Prečišćivači"
      />
      <HeroNewsSlider />
      <section class="section-4">
        <div class="container">
          <div class="section-4-holder-title">
            Šta eliminišu prečišćivači vazduha?
          </div>
          <div class="section-4-holder-images">
            <div class="row">
              <div class="col-sm-2 col-6">
                <img src="/images/vector-1.png" alt="" class="img-fluid" />
                <div class="fw-bold section-4-icon-title">PM 2.5 čestice</div>
              </div>
              <div class="col-sm-2 col-6">
                <img src="/images/vector-2.png" alt="" class="img-fluid" />
                <div class="fw-bold section-4-icon-title">Prašina</div>
              </div>
              <div class="col-sm-2 col-6">
                <img src="/images/vector-3.png" alt="" class="img-fluid" />
                <div class="fw-bold section-4-icon-title">Polen</div>
              </div>
              <div class="col-sm-2 col-6">
                <img src="/images/vector-4.png" alt="" class="img-fluid" />
                <div class="fw-bold section-4-icon-title">Mirisi dima</div>
              </div>
              <div class="col-sm-2 col-6">
                <img src="/images/vector-5.png" alt="" class="img-fluid" />
                <div class="fw-bold section-4-icon-title">Alergeni</div>
              </div>
              <div class="col-sm-2 col-6">
                <img src="/images/vector-6.png" alt="" class="img-fluid" />
                <div class="fw-bold section-4-icon-title">
                  Zaštita od nekih virusa
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-2">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="content-section-statistic">
                <div class="holder-statistic">
                  <div
                    class="holder-statistic-item-left"
                    style={{ backgroundImage: backgroundImage }}
                  >
                    <div class="content-statistic-item-left-center">
                      <p>
                        Kvalitet vazduha
                        <br />u {infoData?.city?.name} <br />{" "}
                        <span style={{ fontSize: "17px" }}>
                          {formattedTime}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="holder-statistic-item-right">
                    <div class="statistic-right-number">
                      <h4 class={`statistic-number-item ${classColor}`}>
                        {infoData?.aqi}
                      </h4>
                      <div class="statistic-number-status">{vazduh}</div>
                    </div>
                    <div class="statistic-right-scale">
                      <div class="statistoc-right-scale-item">
                        <div class="scale-item-name">PM 2.5</div>
                        <div class={`scale-item-line ${scaleLine}`}>
                          <div
                            class={`scale-item-line-percetange ${colorScale}`}
                            style={{
                              width:
                                (infoData?.iaqi?.pm25?.v / 300) * 100 + "%",
                              maxWidth: "100%",
                            }}
                          ></div>
                        </div>
                        <div class="scale-item-value">
                          {infoData?.iaqi?.pm25?.v} µg/m³{" "}
                        </div>
                      </div>
                      <div class="statistoc-right-scale-item">
                        <div class="scale-item-name">PM 10</div>
                        <div class={`scale-item-line ${scaleLine}`}>
                          <div
                            class={`scale-item-line-percetange ${colorScale}`}
                            style={{
                              width:
                                (infoData?.iaqi?.pm10?.v / 300) * 100 + "%",
                              maxWidth: "100%",
                            }}
                          ></div>
                        </div>
                        <div class="scale-item-value">
                          {infoData?.iaqi?.pm10?.v} µg/m³
                        </div>
                      </div>
                      <div class="statistoc-right-scale-item">
                        <div class="scale-item-name">O3</div>{" "}
                        <div class={`scale-item-line ${scaleLine}`}>
                          <div
                            class={`scale-item-line-percetange ${colorScale}`}
                            style={{
                              width: (infoData?.iaqi?.o3?.v / 300) * 100 + "%",
                              maxWidth: "100%",
                            }}
                          ></div>
                        </div>{" "}
                        <div class="scale-item-value">
                          {infoData?.iaqi?.o3?.v} µg/m³{" "}
                        </div>
                      </div>
                      <div class="statistoc-right-scale-item">
                        <div class="scale-item-name">NO2</div>
                        <div class={`scale-item-line ${scaleLine}`}>
                          <div
                            class={`scale-item-line-percetange ${colorScale}`}
                            style={{
                              width: (infoData?.iaqi?.no2?.v / 300) * 100 + "%",
                              maxWidth: "100%",
                            }}
                          ></div>
                        </div>
                        <div class="scale-item-value">
                          {infoData?.iaqi?.no2?.v} µg/m³
                        </div>
                      </div>
                      <div class="statistoc-right-scale-item">
                        <div class="scale-item-name">CO</div>
                        <div class={`scale-item-line ${scaleLine}`}>
                          <div
                            class={`scale-item-line-percetange ${colorScale}`}
                            style={{
                              width: (infoData?.iaqi?.h?.v / 70) * 100 + "%",
                              maxWidth: "100%",
                            }}
                          ></div>
                        </div>
                        <div class="scale-item-value">
                          {infoData?.iaqi?.h?.v} µg/m³
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="new-block-section">
        <div class="full-new-block full-new-block-desktop">
          <div class="full-new-block-left-side"></div>
          <div class="full-new-block-right-side">
            <div class="item-section-holder section-home-3-right-block">
              <h2>
                <span>Zagađenje vazduha</span>
                <br />
                savremen problem koji traži savremeno rešenje
              </h2>
              <h3>
                Čitav region Zapadnog Balkana iz godine u godinu beleži visok
                procenat zagađenosti vazduha.
              </h3>
              <p>
                Problemi po zdravlje prouzrokovani posledicama zagađenja i
                prisustva štetnih čestica u vazduhu primetni su i kod stanovnika
                širom Srbije. Njihova zabrinutost za zdravlje i kvalitet života
                utiče na potrebu da se informišu i edukuju o problemu kako bi
                potražili adekvatne načine njegovog rešavanja.{" "}
              </p>
            </div>
          </div>
        </div>
        <div class="full-new-block full-new-block-mobile">
          <div class="full-new-block-left-side mobile-block-left">
            <div class="d-flex">
              <div class="flex-3">
                <h2>
                  <span>Zagađenje vazduha</span>
                  <br />
                  savremen problem koji traži savremeno rešenje
                </h2>
                <h3>
                  Čitav region Zapadnog Balkana iz godine u godinu beleži visok
                  procenat zagađenosti vazduha.
                </h3>
              </div>
              <div class="flex-1"></div>
            </div>
          </div>
          <div class="full-new-block-right-side">
            <div class="item-section-holder mobile-block-right">
              <div class="d-flex">
                <div class="flex-1"></div>
                <div class="flex-1 padding-item-mobile">
                  <p>
                    Problemi po zdravlje prouzrokovani posledicama zagađenja i
                    prisustva štetnih čestica u vazduhu primetni su i kod
                    stanovnika širom Srbije. Njihova zabrinutost za zdravlje i
                    kvalitet života utiče na potrebu da se informišu i edukuju o
                    problemu kako bi potražili adekvatne načine njegovog
                    rešavanja.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-5">
        <div class="container">
          <div class="row">
            <div class="carousel-products-home">
              <Slider {...settings}>
                <div className="carousel-products-home-item-object">
                  <div class="carousel-products-home-item">
                    <div class="carousel-prodcuts-home-item-holder">
                      <div class="carousel-product-content-item-subtitle"></div>
                      <div
                        class="carousel-product-content-item-title"
                        title="SENCOR SHA 9400WH "
                      >
                        SENCOR SHA 9400WH{" "}
                      </div>
                      <div class="carousel-product-content-item-desc"></div>

                      <div class="carousel-product-content-item-button">
                        <Link to="/proizvod/sencor-sha-9400wh-/57">
                          <button class="btn-info-white-border carousel-btn-detail">
                            Detaljnije{" "}
                            <img src="/images/strelica-desno.png" alt="" />
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div class="carousel-product-content-item-image">
                      <div
                        class="carousel-product-content-item-image-big"
                        style={{
                          backgroundImage:
                            "url(/images/1663153804-sencor-9400wh-png.png)",
                        }}
                      ></div>
                      <div class="carousel-procut-image-item-ident">
                        APA01344
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-products-home-item-object">
                  <div class="carousel-products-home-item">
                    <div class="carousel-prodcuts-home-item-holder">
                      <div class="carousel-product-content-item-subtitle"></div>
                      <div
                        class="carousel-product-content-item-title"
                        title="SHARP UA-PM50E-BS01 sa hvatačem komaraca"
                      >
                        SHARP UA-PM50E-BS01 sa hvatačem komaraca
                      </div>
                      <div class="carousel-product-content-item-desc"></div>

                      <div class="carousel-product-content-item-button">
                        <Link to="/proizvod/sharp-ua-pm50e-bs01-sa-hvatačem-komaraca/54">
                          <button class="btn-info-white-border carousel-btn-detail">
                            Detaljnije{" "}
                            <img src="/images/strelica-desno.png" alt="" />
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div class="carousel-product-content-item-image">
                      <div
                        class="carousel-product-content-item-image-big"
                        style={{
                          backgroundImage:
                            "url(/images/1663161925-pm50-bs01-png.png)",
                        }}
                      ></div>
                      <div class="carousel-procut-image-item-ident">
                        APA01612
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-products-home-item-object">
                  <div class="carousel-products-home-item">
                    <div class="carousel-prodcuts-home-item-holder">
                      <div class="carousel-product-content-item-subtitle"></div>
                      <div
                        class="carousel-product-content-item-title"
                        title="SHARP UA-HD40E-LS02"
                      >
                        SHARP UA-HD40E-LS02
                      </div>
                      <div class="carousel-product-content-item-desc"></div>

                      <div class="carousel-product-content-item-button">
                        <Link to="/proizvod/sharp-ua-hd40e-ls02/35">
                          <button class="btn-info-white-border carousel-btn-detail">
                            Detaljnije{" "}
                            <img src="/images/strelica-desno.png" alt="" />
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div class="carousel-product-content-item-image">
                      <div
                        class="carousel-product-content-item-image-big"
                        style={{
                          backgroundImage:
                            "url(/images/1675163533-image619b8c7545354-png.png)",
                        }}
                      ></div>
                      <div class="carousel-procut-image-item-ident">
                        APA01602
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            <div class="show-all-product-content">
              <Link to="/proizvodi">
                <div class="show-all-products-btn">
                  Prikaži sve <img src="/images/desno.svg" alt="" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section class="section-7 section-7-new-position">
        <div class="container-fluid content-section-home-3">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="item-section-holder section-home-3-right-block section-home-3-right-block-2 section-home-3-right-block-3 item-section-holder-button">
                  <h2>Kako izabrati najbolji prečišćivač vazduha?</h2>
                  <p style={{ color: "#fff;" }}>
                    Prečišćivač vazduha pobrinuće se da se sve štetne materije
                    eliminišu iz vazduha u vašem domu i obezbediće vam svež,
                    čist i zdrav vazduh. Pri odabiru modela prećišćivača vazduha
                    treba obratiti pažnju na brend proizvođača, kvadraturu
                    pokrivenosti prostora, funkcije jonizacije i ovlaživanja
                    vazduha, kao kvalitet i dugotrajnost filtera.
                  </p>
                  <p style={{ color: "#fff;" }}>
                    Ukoliko niste sigurni koji prečišćivač vazduha odgovara
                    vašim potrebama, na pravom ste mestu. Prečišćivači.rs je
                    sajt informativnog karaktera koji vam donosi relevantne
                    podatke na temu značaja zdravog i svežeg vazduha i nastoji
                    da vam kroz edukaciju pomogne da izaberete najbolji
                    prečišćivač vazduha za vaš dom.
                  </p>
                  <Link to="/proizvodi">
                    <button class="btn-info-white-border hover-btn-home">
                      Pogledajte uređaje{" "}
                      <img src="/images/strelica-desno.png" alt="" />{" "}
                      <img
                        src="/images/strelica-desno-hover.png"
                        class="img-hover-strelica-home"
                      />
                    </button>
                  </Link>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="item-section-holder item-section-holder-image">
                  <img
                    src="/images/stanje3.png"
                    alt="Aparat"
                    class="image-absoulte-section image-absoulte-section-new-position"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-8">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="blog-title-homepage-line"></div>
              <div class="blog-title-homepage">Najnovije na blogu</div>
            </div>
            {dataPagesListBlogAll?.slice(0, 2).map((item, key) => (
              <BlogItem item={item} key={key} />
            ))}
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Home;
