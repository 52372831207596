import React from "react";
import { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/stari/HelmetSeo";
import ContactForm from "../../components/Site/Contact";

const PrivacyCookie = () => {
  return (
    <Fragment>
      <HelmetSeo
        title="Politika upotrebe kolacica | Prečišćivači"
        description="Prečišćivači"
        keywords="Prečišćivači"
      />

      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="custom-content-block" style={{ textAlign: "left" }}>
              <h1 class="senExtraBold" style={{ textAlign: "center" }}>
                POLITIKA UPOTREBE KOLAČIĆA
              </h1>
              <div class="row">
                <div class="col-sm-12">
                  <p>
                    Svrha ove politike kolačića jeste da definiše i bolje
                    približi pojam kolačića to jest njihovu svrhu. U Politici
                    kolačića će biti reči o tome šta su kolačići, kako se i zbog
                    čega koriste, koje informacije se skupljaju i šta se radi sa
                    tim informacijama. Takođe, u našoj Politici o privatnosti
                    možete više saznati o tome zašto i na koje načine sakupljamo
                    podatke i šta radimo sa njima.
                  </p>

                  <h3>Šta su kolačići?</h3>
                  <p>
                    Kolačići su tekstualne datoteke na kojima se skladišu manje
                    informacije i koji se čuvaju u Vašem kompjuteru, lap-topu,
                    tabletu ili telefonu kada se veb sajt učita. Koja je njihova
                    svrha? Svrha kolačića jeste da obezbedi normalno i pravilno
                    funkcionisanje veb sajta, da poboljša korisničko iskustvo
                    posetioca sajta, da poboljša bezbednost veb sajta i da
                    sakupe podatke koji će se posle analizirati.
                  </p>
                  <h3>Koje vrste kolačića postoje?</h3>
                  <p>
                    Prva podela jeste na kolačiće prve strane i kolačiće drugih
                    servera. Kolačići prve strane su neophodni za normalno
                    funkcionisanje sajta i oni ne sakupljaju nikakve lične
                    podatke. Pored ove dve vrsta kolačića, sajt prečišćivači.rs
                    koristi i ove vrste: osnovni, marketing, statistički,
                    funkcionalni i preference.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PrivacyCookie;
