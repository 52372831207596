import { toast } from "react-toastify";
import {
  getSeoPagesList,
  seoPageDelete,
  updateSeoItemPath,
} from "../../constants/apiRoutes";
import { seoActions } from "../../reducers/Admin/seoActions";
import apiService from "../../utils/configAxios";

export const updateSeoItem = (dataValue, navigation) => {
  return (dispatch) => {
    dispatch(seoActions.actionStart());
    return apiService
      .post(updateSeoItemPath(), dataValue)
      .then((response) => {
        navigation("/adminManager/seo/pages/list");
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchSeoPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(seoActions.actionStart());
    return apiService
      .get(getSeoPagesList(pageId))
      .then((response) => {
        dispatch(seoActions.fetchAllSeoPagesList(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const deleteSeoPage = (pageId) => {
  return (dispatch) => {
    dispatch(seoActions.actionStart());
    return apiService
      .delete(seoPageDelete(pageId))
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });

        return apiService
          .get(getSeoPagesList(0))
          .then((response) => {
            dispatch(seoActions.fetchAllSeoPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
