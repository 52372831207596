import React from "react";
import { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/stari/HelmetSeo";

const Registration = () => {
  return (
    <Fragment>
      <HelmetSeo
        title="Registracija | Prečišćivači"
        description="Prečišćivači"
        keywords="Prečišćivači"
      />

      <div class="container-fluid contact-page">
        <div class="row">
          <div class="block-content-part-holder" style={{ padding: "0px" }}>
            <div class="block-content-side block-content-form-side">
              <div class="block-content-form-side-holder">
                <div class="contact-title registration-title senExtraBold">
                  Hvala na poverenju!
                </div>
                <div class="contact-info">
                  <p>
                    Ukoliko ste kupili neki od uređaja iz asortimana
                    prečišćivača brendova Xiaomi, Sencor, Sharp ili Beko, na
                    ovoj stranici možete registrovati vašu kupovinu.
                  </p>

                  <p>
                    Registracija kupovine se obavlja popunjavanjem obrasca koji
                    se nalazi u nastavku.
                  </p>
                </div>
                <form id="contact-form" name="registration-form">
                  <input type="hidden" name="anRegistrationId" value="1" />
                  <div class="form-group mb-3">
                    <input
                      type="name"
                      class="form-control"
                      name="firstName"
                      placeholder="Ime"
                      required=""
                    />
                  </div>
                  <div class="form-group mb-3">
                    <input
                      type="name"
                      class="form-control"
                      name="lastName"
                      placeholder="Prezime"
                      required=""
                    />
                  </div>
                  <div class="form-group mb-3">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      placeholder="e-mail adresa"
                      required=""
                    />
                  </div>
                  <div class="form-group mb-3">
                    <input
                      type="number"
                      class="form-control"
                      name="number"
                      placeholder="Broj fiskalnog računa"
                      required=""
                    />
                  </div>
                  <div class="form-group mb-3">
                    <input
                      class="form-control"
                      type="file"
                      name="acFile"
                      id="acFile"
                      required=""
                    />
                  </div>
                  <input
                    type="hidden"
                    id="g-recaptcha-response"
                    name="g-recaptcha-response"
                    value="
                  "
                  />
                  <button
                    type="button"
                    class="contact-button reg-button"
                    id="registration-button-form-submit"
                  >
                    Registrujte uređaj
                  </button>
                </form>
              </div>
            </div>
            <div class="block-content-side block-content-image-side">
              <div
                class="image-registration-banner"
                style={{
                  backgroundImage: "url(/images/reg.jpg)",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Registration;
