import {
  Button,
  Input,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";
import InputField from "../../components/Admin/Reusable/InputField";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor } from "ckeditor5-custom-build/build/ckeditor";
import Breadcrumb from "../../components/Admin/Reusable/Breadcrumb";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import InputAdornment from "@mui/material/InputAdornment";

const Product = () => {
  const [selectedFile, setSelectedFile] = useState("");
  const [truckQty, setTruckQty] = useState(false);
  const [physicalProduct, setPhysicalProduct] = useState(false);

  const initialState = {
    acName: "",
    acDocument: "",
    anPriceSale: "",
    anMargin: "",
    anProfit: "",
    anQty: 0,
    anPrice: "",
    anCostPerItem: "",
    anWeight: "",
    acSeoTitle: "",
    acSeoDescription: "",
    anStatus: 1,
    anTypeWeight: 3,
  };

  const [data, setData] = useState(initialState);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));

    if (name == "anCostPerItem") {
      if (value != "") {
        setData((prevUser) => ({
          ...prevUser,
          anProfit: data.anPrice - value,
          anMargin: parseFloat(
            ((data.anPrice - value) / data.anPrice) * 100
          ).toFixed(1),
        }));
      } else {
        setData((prevUser) => ({
          ...prevUser,
          anProfit: 0,
          anMargin: 0,
        }));
      }
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSizeInMB = file.size / 1048576; // Konvertujemo veličinu u MB
      if (fileSizeInMB <= 5) {
        setSelectedFile(file);
      } else {
        toast.error(
          "Odabrana slika je prevelika. Maksimalna veličina je 3MB.",
          { theme: "colored" }
        );
        setData((prevUser) => ({
          ...prevUser,
          acDocument: null,
        }));
        event.target.value = null; // Resetujemo input
      }
    }
  };

  const handleCheckboxChange = (event) => {
    setTruckQty(event.target.checked);
  };

  const handleCheckboxChangePhysicalProduct = (event) => {
    setPhysicalProduct(event.target.checked);
  };

  return (
    <div className="product-detail-admin">
      <Breadcrumb title="Proizvod" type="product-forma" />
      <div className="container">
        <div className="row">
          <div className="col-8">
            <div className="card">
              <div className="card-body">
                <form noValidate>
                  <div className="row">
                    <div className="col-12">
                      <InputField
                        required
                        label="Naziv proizvoda"
                        name="acName"
                        value={data.acName}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        className="mb-1"
                      />
                    </div>
                    <div className="col-12">
                      <InputLabel
                        id="demo-simple-select-label"
                        className="mb-2"
                      >
                        Opis:
                      </InputLabel>
                      <CKEditor
                        name="acDescription"
                        editor={Editor}
                        data={data.acDescription}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setData((prevUser) => ({
                            ...prevUser,
                            acDescription: data,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <h1 className="card-title mb-0 flex-grow-1">
                  {"Cene proizvoda"}
                </h1>
              </div>
              <div className="card-body">
                <form noValidate>
                  <div className="row">
                    <div className="col-6">
                      <InputField
                        required
                        type="number"
                        label="Cena"
                        placeholder={`0.00`}
                        name="anPrice"
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              RSD
                            </InputAdornment>
                          ),
                        }}
                        value={data.anPrice}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        className="mb-1"
                      />
                    </div>
                    <div className="col-6">
                      <InputField
                        type="number"
                        placeholder={`0.00`}
                        label="Cena pre popustom"
                        name="anPriceSale"
                        value={data.anPriceSale}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              RSD
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        className="mb-1"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="card-footer">
                <div className="row">
                  <div className="col-4">
                    <InputField
                      type="number"
                      placeholder={`0.00`}
                      label="Cena po stavci"
                      name="anCostPerItem"
                      value={data.anCostPerItem}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">RSD</InputAdornment>
                        ),
                      }}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-1"
                    />
                  </div>
                  <div className="col-4">
                    <InputField
                      type="number"
                      label="Profit"
                      placeholder={`0.00`}
                      name="anProfit"
                      value={data.anProfit}
                      fullWidth
                      inputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">RSD</InputAdornment>
                        ),
                      }}
                      size="small"
                      className="mb-1"
                    />
                  </div>
                  <div className="col-4">
                    <InputField
                      type="number"
                      label="Marza"
                      placeholder={`0.00`}
                      name="anMargin"
                      value={data.anMargin}
                      fullWidth
                      inputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      size="small"
                      className="mb-1"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <h1 className="card-title mb-0 flex-grow-1">{"Lager"}</h1>
              </div>
              <div className="card-body">
                <form noValidate>
                  <div className="row">
                    <div className="col-12">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox onChange={handleCheckboxChange} />}
                          label="Pratite količinu"
                          className="mb-2"
                        />
                      </FormGroup>
                    </div>
                    {truckQty && (
                      <div className="col-12">
                        <div className="row">
                          <div className="col-sm-6">
                            <InputField
                              type="number"
                              label="Kolicina"
                              name="anQty"
                              value={data.anQty}
                              onChange={handleInputChange}
                              fullWidth
                              size="small"
                              className="mb-2"
                            />
                          </div>
                        </div>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Nastavi prodaju i kada je proizvod van zaliha"
                          />
                          <small>
                            Osoblje će videti upozorenje, ali može završiti
                            prodaju kada dostupna količina dosegne nulu i ispod
                            nje
                          </small>
                        </FormGroup>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <h1 className="card-title mb-0 flex-grow-1">{"Dostava"}</h1>
              </div>
              <div className="card-body">
                <form noValidate>
                  <div className="row">
                    <div className="col-12">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleCheckboxChangePhysicalProduct}
                            />
                          }
                          label="Ovo je fizički proizvod"
                          className="mb-2"
                        />
                      </FormGroup>
                    </div>
                    {physicalProduct && (
                      <div className="col-12">
                        <div className="row">
                          <div className="col-sm-4">
                            <InputField
                              type="number"
                              label="Tezina"
                              name="anWeight"
                              value={data.anWeight}
                              onChange={handleInputChange}
                              fullWidth
                              size="small"
                              className="mb-2"
                            />
                          </div>
                          <div className="col-sm-2">
                            <FormControl fullWidth className="mb-1">
                              <InputLabel id="demo-simple-select-label">
                                Tip
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={data.anTypeWeight}
                                label="Tip"
                                name="anTypeWeight"
                                onChange={handleInputChange}
                                size="small"
                              >
                                <MenuItem value="1">lb</MenuItem>
                                <MenuItem value="2">oz</MenuItem>
                                <MenuItem value="3">kg</MenuItem>
                                <MenuItem value="4">g</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <h1 className="card-title mb-0 flex-grow-1">
                  {"SEO optimizacija"}
                </h1>
              </div>
              <div className="card-body">
                <form noValidate>
                  <div className="row">
                    <h6 className="mb-3">Listanje na pretraživaču</h6>
                    <small className="mb-3">
                      Dodajte naslov i opis da biste videli kako ovaj proizvod
                      može izgledati u listanju na pretraživaču.
                    </small>
                    <div className="col-12">
                      <InputField
                        required
                        label="Naslov stranice"
                        name="acSeoTitle"
                        value={data.acSeoTitle}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        className="mb-0"
                      />
                      <small> 0 od 70 iskoriscenih karaktera</small>
                    </div>
                    <div className="col-12">
                      <InputField
                        required
                        multiline
                        rows={4}
                        label="Meta opis"
                        name="acSeoDescription"
                        value={data.acSeoDescription}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        className="mt-4 mb-0"
                      />
                      <small> 0 od 360 iskoriscenih karaktera</small>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="card">
              <div className="card-header">
                <h1 className="card-title mb-0 flex-grow-1">
                  {"Status proizvoda"}
                </h1>
              </div>
              <div className="card-body">
                <form noValidate>
                  <div className="row">
                    <div className="col-12">
                      <FormControl fullWidth className="mt-1 mb-1">
                        <InputLabel id="demo-simple-select-label">
                          Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={data.anStatus}
                          label="Status"
                          name="anStatus"
                          onChange={handleInputChange}
                          size="small"
                        >
                          <MenuItem value="1">Aktivan</MenuItem>
                          <MenuItem value="0">Neaktivan</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <h1 className="card-title mb-0 flex-grow-1">
                  {"Struktura proizvoda"}
                </h1>
              </div>
              <div className="card-body">
                <form noValidate>
                  <div className="row">
                    <div className="col-12">
                      <InputField
                        required
                        label="Ime i prezime"
                        name="acName"
                        value={data.acName}
                        fullWidth
                        size="small"
                        className="mb-1"
                      />
                    </div>
                    <div className="col-12">
                      <InputField
                        required
                        label="Ime i prezime"
                        name="acName"
                        value={data.acName}
                        fullWidth
                        size="small"
                        className="mb-1"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <h1 className="card-title mb-0 flex-grow-1">
                  {"Glavna slika proizvoda"}
                </h1>
              </div>
              <div className="card-body">
                <form noValidate>
                  <div className="row">
                    <div className="col-12">
                      <Input
                        required
                        type="file"
                        className="form-control"
                        onChange={(e) => {
                          if (e.target.files.length === 0) {
                            setSelectedFile(null);
                          } else {
                            handleFileSelect(e);
                          }
                        }}
                        id="file-input"
                        name="acFileName"
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor="file-input"
                        style={{ position: "relative" }}
                      >
                        <Button variant="contained" component="span">
                          Priložite sliku
                        </Button>
                      </label>
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ marginTop: "10px" }}
                      >
                        <small>
                          <i>
                            (maksimalna veličina slike do <b>5MB</b>)
                          </i>
                        </small>
                        <br />
                        <small>
                          <i>
                            (dozvoljeni formati <b>.jpg, .jpeg, .png, .svg</b>)
                          </i>
                        </small>
                      </InputLabel>
                    </div>
                    <div className="col-sm-12">
                      {selectedFile && (
                        <div>
                          <table className="table preview-image-holder-table">
                            <tbody>
                              <tr>
                                <td>
                                  {selectedFile && (
                                    <div className="preview-image-holder-admin">
                                      <img
                                        src={URL.createObjectURL(selectedFile)}
                                        alt={selectedFile.name}
                                      />
                                    </div>
                                  )}
                                  Odabrana slika: <b>{selectedFile.name}</b>{" "}
                                  (Veličina:{" "}
                                  {(selectedFile.size / 1048576).toFixed(2)} MB)
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    type="button"
                                    startIcon={<DeleteIcon />}
                                    size="small"
                                    onClick={() => {
                                      setSelectedFile(null);
                                      document.getElementById(
                                        "file-input"
                                      ).value = ""; // Resetovanje input polja za datoteku
                                    }}
                                  >
                                    Obrisi sliku
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <p></p>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
