import React, { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/stari/HelmetSeo";
import Contact from "../../components/Site/Contact";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchBlogListItems } from "../../actions/Site/global";
import { useState } from "react";
import i18next from "i18next";
import BlogList from "./BlogList";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../utils";
import BlogItem from "./BlogItem";

const Blog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { dataPagesListBlogAll } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchBlogListItems());
  }, [dispatch]);

  return (
    <Fragment>
      <HelmetSeo
        title="Blog | Prečišćivači"
        description="Prečišćivači"
        keywords="Prečišćivači"
      />
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-5 p-0">
            <div
              class="image-banner-top"
              style={{
                backgroundImage: "url(/images/baner2.png)",
              }}
            ></div>
          </div>
          <div class="col-sm-7 content-banner-text-backgorund">
            <div class="row">
              <div class="col-xxl-8 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12">
                <div class="image-banner-top-content">
                  <h1>
                    Korisni saveti i sve informacije o prečišćivačima vazduha na
                    jednom mestu
                  </h1>
                  <p>
                    Niste sigurni da li vam je neophodan prečišćivač vazduha,
                    čemu on služi i kako funkcioniše ili vam je potrebna pomoć u
                    odabiru pravog modela za vaš dom?
                  </p>

                  <h2>Na pravom ste mestu!</h2>

                  <p>
                    Blog stranica je osmišljena da pruži sve relevantne
                    informacije i korisne savete koji vam mogu pomoći u odabiru
                    pravog prečišćivača vazduha za vaše i potrebe vašeg doma.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container blog-content-list">
        <div class="row">
          {dataPagesListBlogAll?.map((item, key) => (
            <BlogItem item={item} />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default Blog;
