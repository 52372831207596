import React, { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/stari/HelmetSeo";
import HeroOther from "../../components/Site/HeroOther";
import Contact from "../../components/Site/Contact";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState } from "react";
import i18next from "i18next";
import { useEffect } from "react";
import {
  fetchBlogDetailListItems,
  fetchBlogListItems,
} from "../../actions/Site/global";
import DescriptionRender from "../../components/Site/DescriptionRender";
import { baseUrl, formatDateShort } from "../../utils";

const BlogDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { dataPagesListBlogAll } = useSelector((state) => state.globalReducer);
  console.log(dataPagesListBlogAll, "dataPagesListBlogAll");
  useEffect(() => {
    dispatch(fetchBlogListItems(id));
  }, [dispatch]);

  return (
    <Fragment>
      <HelmetSeo
        title={`${dataPagesListBlogAll[0]?.acTitle} | Prečišćivači`}
        description={dataPagesListBlogAll[0]?.acSeoDescription}
        keywords={dataPagesListBlogAll[0]?.acSeoKeywords}
        ogType="article"
        ogTitle={dataPagesListBlogAll[0]?.acTitle}
        ogDescription={dataPagesListBlogAll[0]?.acSeoDescription}
        ogImage={baseUrl + "images/" + dataPagesListBlogAll[0]?.acImage}
        ogUrl={`https://www.preciscivaci.rs/blog/blog-detaljno/${id}`}
        ogsiteName="Prečišćivači"
        ogImageWidth="1200"
        ogImageHeight="800"
      />
      {dataPagesListBlogAll[0] && (
        <>
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12 padding-0">
                <img
                  src={baseUrl + `images/${dataPagesListBlogAll[0].acImage}`}
                  alt="Prečišćivači"
                  class="img-fluid main-image-blog desktop-brand-image-main"
                />
                <img
                  src={
                    baseUrl + `images/${dataPagesListBlogAll[0].acImageSmall}`
                  }
                  alt="Prečišćivači"
                  class="img-fluid main-image-blog mobile-brand-image-main"
                />
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <div class="blog-content-holder">
                  <h3 class="subtitle-blog-item-homepage">
                    {dataPagesListBlogAll[0].acSubtitle}
                  </h3>
                  <h1 class="blog-item-title senExtraBold">
                    {dataPagesListBlogAll[0].acTitle}
                  </h1>
                  <div
                    class="blog-item-body"
                    dangerouslySetInnerHTML={{
                      __html: dataPagesListBlogAll[0].acDescription,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default BlogDetail;
