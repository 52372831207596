import React from "react";
import { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/stari/HelmetSeo";

const Privacy = () => {
  return (
    <Fragment>
      <HelmetSeo
        title="Politika privatnosti | Prečišćivači"
        description="Prečišćivači"
        keywords="Prečišćivači"
      />

      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="custom-content-block" style={{ textAlign: "left" }}>
              <h1 class="senExtraBold" style={{ textAlign: "center" }}>
                OPŠTI USLOVI WEB SAJTA prečišćivači.rs
              </h1>
              <div class="row">
                <div class="col-sm-12">
                  <p>
                    Radi otklanjanja svake sumnje, korišćenjem sajta
                    prečišćevači.rs korisnik potvrđuje da je prethodno upoznat i
                    da je saglasan sa ovim Opštim uslovima, koji su mu bili
                    dostupni, čitki i razumljivi.
                  </p>
                  <h4>
                    <b>I Uvodne odredbe i terminologija</b>
                  </h4>

                  <p>
                    Sajt prikazan na adresi prečišćevači.rs (u daljem tekstu:
                    Sajt) je razvijen od strane Ewe Comp doo, Cara Dušana 212,
                    Zemun, MB: 17333127, PIB: 100042618 (u daljem tekstu: Ewe).
                  </p>
                  <p>
                    Sadržaj Sajta i pružanje usluga putem Sajta regulisano je
                    Zakonom o obligacionim odnosima ("Sl. list SFRJ", br. 29/78,
                    39/85, 45/89 - odluka USJ i 57/89, "Sl. list SRJ", br. 31/93
                    i "Sl. list SCG", br. 1/2003 - Ustavna povelja), Zakonom o
                    zaštiti podataka o ličnosti ("Sl. glasnik RS", br. 87/2018),
                    Zakonom o oglašavanju ("Sl. glasnik RS", br. 6/2016), kao i
                    drugim pozitivnim propisima Republike Srbije.
                    prečišćevači.rs se obavezuje da će korisniku pružati usluge
                    u skladu sa ovim Opštim uslovima na način i sa ograničenjima
                    propisanim u ovim Opštim uslovima, a u skladu sa pozitivnim
                    propisima Republike Srbije.
                  </p>
                  <p>
                    Za potrebe ovog dokumenta koristiće se sledeća
                    terminologija:
                  </p>

                  <p>
                    <b>Korisnik</b>, u smislu Opštih uslova, je lice koje
                    koristi Sajt (u daljem tekstu: Korisnik, u množini
                    Korisnici).
                  </p>
                  <p>
                    <b>Roba</b>, u smislu Opštih uslova, su prečišćivači vazduha
                    proizvođača Sencor, Sharp, Xiomi I Beko (u daljem tekstu:
                    Roba).
                  </p>
                  <p>Konstatuju se sledeće činjenice:</p>
                  <ul style={{ listStyle: "none", important: true }}>
                    <li>
                      {" "}
                      1) Sajt je namenjen informisanju Korisnika u pogledu Robe
                      – njenim karakteristikama, cenama, pogodnostima;
                    </li>
                    <li>
                      2) Korisnik koji je prethodno kupio Robu, posetom Sajtu
                      može registrovati realizovanu kupovinu kako bi ostvario
                      određene pogodnosti prikazane na Sajtu.
                    </li>
                  </ul>
                  <p>
                    Radi otklanjanja svake sumnje, konstatuje se da
                    prečišćevači.rs ne vrši prodaju Robe Korisnicima putem
                  </p>
                  <p>Sajta i ne može se smatrati prodavcem.</p>
                  <p>
                    Sajt, pored navedenih usluga može sadržati i druge usluge
                    koje se pružaju Korisniku.
                  </p>
                  <h4>
                    <b>II Ograničenje odgovornosti</b>
                  </h4>
                  <p>
                    Sajt može sadržati netačne podatke, slovne greške, kao i
                    greške softversko-tehničke prirode usled kojih
                    prečišćevači.rs može po sopstvenom nahođenju i bez prethodne
                    saglasnosti Korisnika vršiti odgovarajuće izmene. Izmene
                    Sajta se mogu izvršiti u bilo kom trenutku bez postojanja
                    obaveze prečišćevači.rs da o tome obavesti Korisnike.
                    prečišćevači.rs ne garantuje da će Sajt funkcionisati bez
                    prekida ili grešaka, da će nedostaci biti blagovremeno
                    ispravljani ili da će Sajt biti kompatibilan sa hardverom
                    ili softverskim rešenjima koja Korisnik koristi.
                    prečišćevači.rs zadržava pravo i da privremeno ili trajno
                    izbriše svu sadržinu sa Sajta.
                  </p>

                  <h4>
                    <b>III Privatnost</b>
                  </h4>
                  <p>
                    Konstatuje se da je prečišćevači.rs rukovalac obrade
                    podataka o ličnosti imajući u vidu da samostalno određuje
                    svrhu i način obrade podataka o ličnosti.
                  </p>
                  <p>
                    prečišćevači.rs čuva privatnost svih Korisnika i štiti
                    njihove podatke o ličnosti u skladu sa pozitivnim propisima
                    Republike Srbije, a u srazmeri sa svrhom njihove obrade.
                    prečišćevači.rs konstatuje da su vrste radnji obrade
                    prikupljanje podataka o ličnosti, njihovo beleženje i
                    razvrstavanje.
                  </p>
                  <p>
                    prečišćevači.rs, zarad praćenja posećenosti, optimizacije i
                    ostvarivanja svrhe i namene Sajta može koristiti Google
                    Analytics, platformu analitičkih usluga koje pruža Google,
                    LLC (u daljem tekstu: Google). Google Analytics omogućava
                    prečišćevači.rs-u lakšu analizu korišćenja Sajta od strane
                    Korisnika i to upotrebom geolokacijskih tehnologija.
                    Informacije koje se na ovaj način prikupljaju se prenose i
                    čuvaju na serverima Google koji se nalaze u Sjedinjenim
                    Američkim Državama. Google, koristeći odgovarajuće mere
                    zaštite, obrađuje informacije u vezi sa aktivnostima na web
                    sajtu i upotrebom Interneta. Google će možda proslediti ove
                    informacije trećim licima u situacijama u kojima to zakon
                    nalaže, ili kada pomenuta treća lica obrađuju gorepomenute
                    podatke u ime Google-a. Korišćenjem Usluga, Korisnik
                    pristaje da Google obrađuje podatak o ličnosti – geolokaciju
                    Korisnika, na ovde opisani način i u gorepomenute svrhe, te
                    se konstatuje da je Google obrađivač podataka o ličnosti.
                    Google će ovaj podatak čuvati 26 meseci posle čega će
                    automatski biti izbrisan.
                  </p>
                  <p>
                    Pored geolokacijskih parametara, prečišćevači.rs, zarad
                    ostvarivanja svrhe i namene Sajta, prikuplja, beleži i
                    razvrstava sledeće podatke o ličnosti:
                  </p>

                  <ul style={{ listStyle: "none", important: true }}>
                    <li>1) Ime i prezime Korisnika;</li>
                    <li>2) E-mail adresa;</li>
                    <li>3) Broj fiskalnog isečka;</li>
                    <li>4) Kontakt telefon;</li>
                  </ul>

                  <p>
                    Vaši podaci o ličnosti se čuvaju 2 (slovima: dve) godine, a
                    posle isteka tog perioda će biti izbrisani.
                  </p>
                  <p>
                    Osnov ovakve obrade podataka o ličnosti na Sajtu zasniva se
                    na pristanku Korisnika kao i na legitimnom interesu
                    prečišćevači.rs.
                  </p>
                  <p>
                    Zakonski propisi o zaštiti podataka o ličnosti omogućavaju
                    Vam širok spektar prava u pogledu eventualne obrade Vaših
                    podataka o ličnosti od strane prečišćevači.rs. Ova prava
                    uključuju pravo na informisanost, pravo na pristup i kopiju
                    Vaših podataka o ličnosti, pravo na ispravku Vaših podataka
                    o ličnosti, pravo na brisanje Vaših podataka o ličnosti,
                    pravo na ograničenje obrade Vaših podataka o ličnosti, pravo
                    na prenosivost podataka o ličnosti i pravo na prigovor
                    Povereniku za informacija od javnog značaja i zaštitu
                    podatak o ličnosti.
                  </p>
                  <p>
                    Za sva ostala pitanja iz oblasti zaštite podataka o ličnosti
                    primenjivaće se Zakon o zaštiti podataka o ličnosti ("Sl.
                    glasnik RS", br. 87/2018).
                  </p>

                  <h4>
                    <b>IV Internet sajtovi trećih lica</b>
                  </h4>
                  <p>
                    Radi ispunjenja svrhe i namene Sajta, zbog potrebe
                    poboljšanja funkcionalnosti, referenci, kao i zbog
                    optimizacije sajta u kontekstu internet pretrage, Sajt može
                    sadržati linkove ka drugim sajtovima u vlasništvu trećih
                    lica. Obezbeđujući takve linkove, prečišćevači.rs ne daje
                    svoju saglasnost u pogledu sadržaja tih web sajtova, niti
                    prihvata ikakvu odgovornost za sadržaj tih sajtova i
                    bezbednost preusmerenog Korisnika, u bilo kom obliku.
                    Korisnik pristupa takvim web sajtovima na vlastiti rizik i
                    korišćenje tih sajtova je uređeno na način određen od strane
                    njihovih vlasnika. prečišćevači.rs će, nakon saznanja da
                    sajtovi trećih lica sadrže nezakonite informacije ukloniti
                    navedene linkove.
                  </p>

                  <h4>
                    <b>V Odgovornosti i obaveze Korisnika</b>
                  </h4>
                  <p>
                    Korisnik je u potpunosti odgovoran za sadržaj i tačnost
                    informacija koje pruža korišćenjem Sajta, kao i za kršenje
                    prava bilo kojeg trećeg lica koje nastaje korišćenjem Sajta.
                  </p>
                  <p>
                    Odgovornost Korisnika podrazumeva, ali se ne ograničava na:
                  </p>

                  <ul style={{ listStyle: "none", important: true }}>
                    <li>1) bilo kakve nezakonite radnje;</li>
                    <li>
                      2) neovlašćeno korišćenje podataka bilo kojeg fizičkog ili
                      pravnog lica;
                    </li>
                    <li>
                      3) slanje irelevantnih ili zlonamernih podataka-tzv. spam.
                    </li>
                    <li>
                      4) unošenje ili pokušaj unošenja kompjuterskih virusa, kao
                      i svaki drugi pokušaj kompromitovanja bezbednosti Sajta;
                    </li>
                    <li>
                      5) testiranje, skeniranje sistema ili mreže, posebno
                      traganje za slabostima Sajta;
                    </li>
                  </ul>

                  <h4>
                    <b>VI Izmene i dopune opštih uslova</b>
                  </h4>
                  <p>
                    prečišćevači.rs zadržava pravo da izvrši delimične ili
                    potpune promene Opštih uslova, uz prethodno obaveštavanje
                    Korisnika putem Sajta, a svako dalje korišćenje Sajta koje
                    usledi od strane Korisnika će se smatrati prihvatanjem
                    izmena i dopuna ovih Opštih uslova.
                  </p>
                  <h4>
                    <b>VII Rešavanje sporova i merodavno pravo</b>
                  </h4>
                  <p>
                    Prihvatanjem ovih Opštih uslova Korisnik je saglasan da je
                    za bilo kakav spor koji nastane usled korišćenja Sajta
                    merodavno pravo Republike Srbije, a nadležan stvarno
                    nadležni sud u Beogradu.
                  </p>
                  <h4>
                    <b>VIII Prelazne i završne odredbe</b>
                  </h4>
                  <p>
                    Ukoliko je bilo koja odredba ovih Opštih uslova, ništava,
                    neprimenljiva ili neizvršiva, ili to postane, ista neće
                    uticati na važenje, primenljivost i izvršivost ostalih
                    odredaba, pri čemu će se takva odredba zameniti, u meri u
                    kojoj je to dozvoljeno pozitivnim pravom, pravno važećom,
                    primenljivom i izvršivom odredbom koja je pravno i ekonomski
                    najbliža ništavoj, neprimenljivoj ili neizvršivoj odredbi.
                  </p>
                  <p>
                    Ovi Opšti uslovi počinju da se primenjuju danom njihovog
                    objavljivanja na Sajtu.
                  </p>
                  <p>
                    Korisnik prihvata da će, ukoliko je potrebno, svu
                    komunikaciju sa prečišćevači.rs po pitanju ovih Opštih
                    uslova inicirati slanjem e-mail-a na adresu
                    preciscivaci@ewe.rs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Privacy;
