import { TextField } from "@mui/material";
import { isUndefined } from "lodash";
import React from "react";

const InputField = (props) => {
  const {
    label,
    name,
    className,
    helperText,
    value,
    placeholder,
    onChange,
    required,
    disabled,
    multiline = false,
    rows,
    size,
    defaultValue,
    inputLabelProps = {},
    inputProps = {},
    error,
    type = "input",
  } = props || {};

  const inputValue = value === null ? "" : value;
  const errorMessage = typeof error !== "string" ? error?.[name] : error;
  const hasError = !isUndefined(errorMessage);

  return (
    <TextField
      label={label}
      name={name}
      defaultValue={defaultValue}
      rows={rows}
      multiline={multiline}
      error={hasError}
      className={`${className}`}
      helperText={errorMessage ? errorMessage : helperText}
      value={inputValue}
      onChange={(event) => {
        onChange?.(event);
      }}
      disabled={!!disabled}
      required={required}
      fullWidth
      size={size}
      type={type}
      InputLabelProps={inputLabelProps}
      InputProps={inputProps}
      placeholder={placeholder}
    />
  );
};

export default InputField;
