import {
  sentContactFormPath,
  getClientsListItems,
  getQaListItems,
  getCaseStudyListItems,
  getDocumentsListItems,
  getDocumentsAllListItems,
  getTestimonialsListItems,
  getBlogAllListItems,
  getBlogDetailListItems,
  getBlogAllListCategoryItems,
  getProductsAllListItems,
  getPartnersAllListItems,
  getSpecificationAllListItems,
  getInfoListItems,
} from "../../constants/apiRoutes";
import { globalActions } from "../../reducers/Site/globalActions";
import apiService from "../../utils/configAxios";
import i18next from "i18next";

export const fetchContactFormSent = (dataValues) => {
  return (dispatch) => {
    return apiService
      .post(sentContactFormPath(), dataValues)
      .then((response) => {
        console.log(response, "respo");
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchClientsListItems = () => {
  return (dispatch) => {
    dispatch(globalActions.actionStart());
    return apiService
      .get(getClientsListItems())
      .then((response) => {
        dispatch(globalActions.fetchItemsListClients(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchQaListItems = (type) => {
  return (dispatch) => {
    return apiService
      .get(getQaListItems(i18next.language, type))
      .then((response) => {
        dispatch(globalActions.fetchItemsListQa(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchCaseStudyListItems = () => {
  return (dispatch) => {
    return apiService
      .get(getCaseStudyListItems(i18next.language))
      .then((response) => {
        dispatch(globalActions.fetchItemsListCaseStudy(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchDocumentsListItems = () => {
  return (dispatch) => {
    return apiService
      .get(getDocumentsListItems(i18next.language))
      .then((response) => {
        dispatch(globalActions.fetchItemsListDocuments(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchTestimonialsListItems = () => {
  return (dispatch) => {
    return apiService
      .get(getTestimonialsListItems(i18next.language))
      .then((response) => {
        dispatch(globalActions.fetchItemsListTestimonials(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchDocumentsAllListItems = () => {
  return (dispatch) => {
    return apiService
      .get(getDocumentsAllListItems(i18next.language))
      .then((response) => {
        dispatch(globalActions.fetchItemsListDocumentsAll(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchBlogListItems = (id = 0) => {
  return (dispatch) => {
    return apiService
      .get(getBlogAllListItems(id))
      .then((response) => {
        dispatch(globalActions.fetchItemsListBlogAll(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchInfoListItems = (id = 0) => {
  return (dispatch) => {
    return apiService
      .get(getInfoListItems())
      .then((response) => {
        dispatch(globalActions.fetchInfoListBlogAll(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchProductsListItems = (id = 0) => {
  return (dispatch) => {
    return apiService
      .get(getProductsAllListItems(id))
      .then((response) => {
        dispatch(globalActions.fetchItemsListProducts(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchSpecificationListItems = (id = 0) => {
  return (dispatch) => {
    return apiService
      .get(getSpecificationAllListItems(id))
      .then((response) => {
        dispatch(globalActions.fetchItemsListSpecification(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchPartnersListItems = (id = 0) => {
  return (dispatch) => {
    return apiService
      .get(getPartnersAllListItems(id))
      .then((response) => {
        dispatch(globalActions.fetchItemsListPartners(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchBlogDetailListItems = (id = 0) => {
  return (dispatch) => {
    return apiService
      .get(getBlogDetailListItems(i18next.language, id))
      .then((response) => {
        dispatch(globalActions.fetchItemsListBlogAll(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchBlogListCategoryItems = (categoryName = "", page, limit) => {
  return (dispatch) => {
    return apiService
      .get(
        getBlogAllListCategoryItems(i18next.language, categoryName, page, limit)
      )
      .then((response) => {
        dispatch(globalActions.fetchItemsListBlogAll(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};
