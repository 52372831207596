import React, { useEffect } from "react";
import { Fragment } from "react";
import Breadcrumb from "../../components/Admin/Reusable/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import InputField from "../../components/Admin/Reusable/InputField";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { isEmpty } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { basicFormValidationHandler } from "../../utils";
import {
  fetchRolePagesList,
  fetchCreateNewRole,
  fetchEditRole,
} from "../../actions/Admin/role";
import { useDispatch, useSelector } from "react-redux";

const Role = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dataPagesDetail } = useSelector((state) => state.roleReducer);
  const { setMenuItemsList } = useSelector((state) => state.loggedUserReducer);

  const initialState = {
    acName: "",
    anStatus: 1,
  };

  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(fetchRolePagesList(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (dataPagesDetail && Object.keys(dataPagesDetail).length > 0) {
      setData(dataPagesDetail[0]);
      setSelectedOptions(dataPagesDetail[0]["privileges"]);
    }
  }, [dataPagesDetail]);

  const roleValidationHandler = () => {
    const { acName } = data;
    const errorsObject = basicFormValidationHandler({
      acName,
    });
    setErrors(errorsObject);
    if (!isEmpty(errorsObject))
      toast.error("Morate popuniti sva obavezna polja", { theme: "colored" });
    return !isEmpty(errorsObject);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (roleValidationHandler()) {
      return;
    }

    const formData = new FormData();

    formData.append("acName", data.acName);
    formData.append("anStatus", data.anStatus);
    formData.append("moduleList", selectedOptions);

    if (id) {
      formData.append("anId", id);
      dispatch(fetchEditRole(formData, navigate));
    } else {
      dispatch(fetchCreateNewRole(formData, navigate));
    }
  };

  const handleCheckboxChange = (event, option) => {
    if (event.target.checked) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="Role" type="role-forma" />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h1 className="card-title mb-0 flex-grow-1">
                {/* Kreiranje novog pitanja */}
                {id ? `Izmena role` : "Kreiranje nove role"}
              </h1>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit} noValidate>
                <div className="row">
                  <div className="col-6">
                    <InputField
                      required
                      label="Naziv"
                      name="acName"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acName}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />
                    <FormControl fullWidth className="mb-4">
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.anStatus}
                        label="Status"
                        name="anStatus"
                        onChange={handleInputChange}
                        size="small"
                      >
                        <MenuItem value="1">Aktivan</MenuItem>
                        <MenuItem value="0">Neaktivan</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-6">
                    <FormGroup>
                      <InputLabel id="demo-simple-select-label">
                        Moduli
                      </InputLabel>
                      {setMenuItemsList?.map((option, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={selectedOptions.includes(option.anId)}
                              onChange={(e) =>
                                handleCheckboxChange(e, option.anId)
                              }
                            />
                          }
                          label={option.acName}
                        />
                      ))}
                    </FormGroup>
                  </div>
                </div>
                <div className="float-end mt-3">
                  {id ? (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<CheckIcon />}
                    >
                      Potvrda izmene
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<AddIcon />}
                    >
                      Kreiraj
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Role;
