import React, { useEffect, useRef, useState } from "react";
import { Fragment } from "react";
import HelmetSeo from "../../components/Site/Reusable/stari/HelmetSeo";
import ContactForm from "../../components/Site/Contact";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { fetchContactFormSent } from "../../actions/Site/global";

const Contact = () => {
  const initialState = {
    acName: "",
    acEmail: "",
    acMessage: "",
    acPhone: "",
    acToken: "",
  };

  const [formData, setFormData] = useState(initialState);
  // const key = "6LeUftEoAAAAAGV2wD_uDEKaIrwHMZFw7sKDgkC9";
  const key = "6Lf6ToAiAAAAAMeMlMz7GZnEci3z2BwiFpuspXc2";
  const dispatch = useDispatch();
  const recaptchaRef = useRef();

  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    if (recaptchaRef?.current) {
      const token = await recaptchaRef?.current.executeAsync();
      setFormData((prevData) => ({ ...prevData, acToken: token }));
    }
  };

  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.acToken) {
      dispatch(fetchContactFormSent(formData));
      alert(
        "Uspešno ste popunili kontakt formu! Uskoro će Vam se neko javiti!"
      );
      setFormData(initialState);
    }
  };

  return (
    <Fragment>
      <HelmetSeo
        title="Kontakt | Prečišćivači"
        description="Prečišćivači"
        keywords="Prečišćivači"
      />

      <div class="container-fluid contact-page">
        <div class="row">
          <div class="block-content-part-holder">
            <div class="block-content-side block-content-form-side">
              <div class="block-content-form-side-holder">
                <div class="contact-title senExtraBold">Kontaktirajte nas</div>
                <div class="contact-subtitle">
                  <span class="karlaBold firm-name-contact">
                    List Computers D.O.O.
                  </span>
                  <img class="img-fluid" src="/images/list-logo.png" />
                </div>
                <ul class="footer-left-links">
                  <li class="hover-item-footer">
                    <a
                      href="http://maps.google.com/?q=Cara Dušana 212, 11080 Zemun, Beograd"
                      target="_blank"
                    >
                      <img
                        src="/images/drop-outline.png?ver=1706386953"
                        class="img-fluid"
                      />
                      &nbsp; Cara Dušana 212, 11080 Zemun, Beograd
                    </a>
                  </li>
                  <li class="hover-item-footer">
                    <a href="mailto:office@list.rs">
                      <img
                        src="/images/mail-outline.png?ver=1706386953"
                        class="img-fluid"
                      />
                      &nbsp;office@list.rs
                    </a>
                  </li>
                  <li class="hover-item-footer">
                    <a href="tel:+381113535555">
                      <img
                        src="/images/phone-outline.png?ver=1706386953"
                        class="img-fluid"
                      />
                      &nbsp;+381 11 35-35-555
                    </a>
                  </li>
                  <li class="hover-item-footer">
                    <a href="tel:+38163389415">
                      <img
                        src="/images/phone-outline.png?ver=1706386953"
                        class="img-fluid"
                      />
                      &nbsp;+381 63 389-415
                    </a>
                  </li>
                </ul>
                <div class="contact-info">
                  Ukoliko imate bilo kakva pitanja ili nedoumice možete nas
                  kontaktirati putem kontakt formulara.
                </div>
                <form
                  id="contact-form"
                  name="contact-form"
                  onSubmit={handleSubmit}
                >
                  <ReCAPTCHA
                    sitekey={key}
                    size="invisible"
                    ref={recaptchaRef}
                  />
                  <div class="form-group mb-3">
                    <input
                      type="name"
                      value={formData.acName}
                      onChange={handleInputChange}
                      class="form-control"
                      name="acName"
                      placeholder="Ime i prezime"
                      required=""
                    />
                  </div>
                  <div class="form-group mb-3">
                    <input
                      type="email"
                      class="form-control"
                      name="acEmail"
                      value={formData.acEmail}
                      onChange={handleInputChange}
                      placeholder="e-mail adresa"
                      required=""
                    />
                  </div>
                  <div class="form-group mb-3">
                    <input
                      type="number"
                      class="form-control"
                      name="acPhone"
                      value={formData.acPhone}
                      onChange={handleInputChange}
                      placeholder="Telefon"
                      required=""
                    />
                  </div>
                  <div class="form-group mb-3">
                    <textarea
                      class="form-control"
                      name="acMessage"
                      value={formData.acMessage}
                      onChange={handleInputChange}
                      placeholder="Poruka"
                      rows="5"
                      required=""
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    class="contact-button"
                    id="contact-button-submit-form"
                  >
                    Pošaljite
                  </button>
                </form>
              </div>
            </div>
            <div class="block-content-side block-content-image-side">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2828.253798191842!2d20.38309031618816!3d44.85712778176409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a65b6a7255555%3A0x6f71a061bf242840!2sList%20Computers%20D.O.O.!5e0!3m2!1sen!2srs!4v1669550957382!5m2!1sen!2srs"
                width="100%"
                height="100%"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Contact;
